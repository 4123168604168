import React, { useState } from 'react'
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
// material
import { Box, Grid, Card, Stack, Switch, TextField, FormControlLabel, Typography, FormHelperText, Container } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import TemplateForm from 'src/sections/@dashboard/template/TemplateForm';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'src/routes/path';
import { useDispatch } from 'react-redux'
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { requestAddTemplate } from 'src/actions/Template.Action';
import { useNavigate } from 'react-router-dom';


const CreateTemplate = () => {
    const isMountedRef = useIsMountedRef();
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setSubmitting] = useState(false)
    const navigate = useNavigate()

    const onSubmitData = (data) => {
        setSubmitting(true)
        dispatch(requestAddTemplate(data, onSuccessAddTemplate, onErrorAddTemplate))
    }

    const onSuccessAddTemplate = (data) => {
        setSubmitting(false)
        enqueueSnackbar('Template created successfully', {
            variant: 'success'
        });
        navigate(PATH_DASHBOARD.general.template_list, { replace: true })
    }

    const onErrorAddTemplate = (error) => {
        const message = error?.response?.data?.message || error?.message || "Please try again."
        if (isMountedRef.current) {
          setSubmitting(false);
          enqueueSnackbar(message, {
            variant: 'error'
          });
        }
    }

    return (
        <Container>
            <HeaderBreadcrumbs
                heading="Add Template"
                links={[
                    { name: 'Home', href: PATH_DASHBOARD.root },
                    { name: 'Template List', href: PATH_DASHBOARD.general.template_list },
                    { name: 'Add Template' }
                ]}
            />
            <TemplateForm onSubmitData={onSubmitData} isLoading={isSubmitting}/>
        </Container>
    )
}

export default CreateTemplate