import * as types from '../constants/ActionTypes'

export const requestCreateCompany = (payload, onSuccess, onError) => ({
    type: types.ADD_COMPANY_REQUEST,
    payload,
    onSuccess,
    onError
})

export const requestGetCompanyList = (onSuccess, onError) => ({
    type: types.LIST_COMPANY_REQUEST,
    onSuccess,
    onError
})

export const requestUpdateCompany = (companyId, payload, onSuccess, onError) => ({
    type: types.UPDATE_COMPANY_REQUEST,
    companyId,
    payload,
    onSuccess,
    onError
})

export const requestUpdateCompanyStatus = (companyId, payload, onSuccess, onError) => ({
    type: types.STATUS_UPDATE_COMPANY_REQUEST,
    companyId,
    payload,
    onSuccess,
    onError
})

export const requestDeleteCompany = (companyId, onSuccess, onError) => ({
    type: types.DELETE_COMPANY_REQUEST,
    companyId,
    onSuccess,
    onError
})

export const requestCompanyWiseClientInfo = (companyCode, onSuccess, onError) => ({
    type: types.COMPANY_WISE_CLIENT_REQUEST,
    companyCode,
    onSuccess,
    onError
})

export const requestCompanyClientStatus = (payload, onSuccess, onError) => ({
    type: types.STATUS_UPDATE_COMPANY_CLIENT_REQUEST,
    payload,
    onSuccess,
    onError
})

export const requestCompanyBulkUploadQuestions = (payload, onSuccess, onError) => ({
    type: types.COMPANY_BULK_UPLOAD_QUESTION,
    payload,
    onSuccess,
    onError
})

export const requestDataFileQuestions = (payload, onSuccess, onError) => ({
    type: types.DATA_FILE_READER_REQUEST,
    payload,
    onSuccess,
    onError
})

export const requestSaveQuestionDraft = (payload, onSuccess, onError) => ({
    type: types.SAVE_QUESTION_DRAFT_REQUEST,
    payload,
    onSuccess,
    onError
})

export const requestChangeDataFileQuestionStatus = (payload, onSuccess, onError) => ({
    type: types.APPROVE_REJECT_QUESTION_REQUEST,
    payload,
    onSuccess,
    onError
})

export const requestGetCompnayFilterRecords = (payload, onSuccess, onError) => ({
    type: types.COMPANY_FILTER_REQUEST,
    payload,
    onSuccess,
    onError
});

export const requestGetSupportingFileList = (companyCode, clientId, onSuccess, onError) => ({
    type: types.SUPPORTING_FILE_LIST_REQUEST,
    companyCode,
    clientId,
    onSuccess,
    onError
});

export const requestUploadSupportingFile = (payload, onSuccess, onError) => ({
    type: types.UPLOAD_SUPPORTING_FILE_REQUEST,
    payload,
    onSuccess,
    onError
});

export const requestGetActiveCompanyList = (payload, onSuccess, onError) => ({
    type: types.ACTIVE_COMPANY_LIST,
    payload,
    onSuccess,
    onError
});