import * as types from '../constants/ActionTypes';

export const loginRequest = (email, password, onSuccess, onError) => ({
  type: types.LOGIN_REQUEST_INITIATED,
  email,
  password,
  onSuccess,
  onError,
});

export const requestLogout = () => ({
  type: types.LOGOUT_REQUEST,
});

export const requestForgotPassword = (email, onSuccess, onError) => ({
  type: types.FORGOT_PASSWORD_REQUEST,
  email,
  onSuccess,
  onError,
});

export const requestGeneratePassword = (email, code, password, onSuccess, onError) => ({
  type: types.GENERATE_PASSWORD_REQUEST,
  email,
  code,
  password,
  onSuccess,
  onError,
});

export const requestUpdatePassword = (payload, token, onSuccess, onError) => ({
  type: types.UPDATE_PASSWORD_REQUEST,
  payload,
  token,
  onSuccess,
  onError,
});

export const requestRefreshToken = (payload, onSuccess, onError) => ({
  type: types.REFRESH_TOKEN_REQUEST,
  payload,
  onSuccess,
  onError,
});

export const requestGetMyAccountInfo = (onSuccess, onError) => ({
  type: types.MY_ACCOUNT_REQUEST,
  onSuccess,
  onError,
});

export const requestGetDashbOardInfo = (onSuccess, onError) => ({
  type: types.DASHBOARD_REQUEST,
  onSuccess,
  onError,
});
