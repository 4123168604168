import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { useState } from 'react';
import { TextField, Alert, Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useParams, useLocation } from 'react-router-dom';
import { requestUpdatePassword } from '../../actions/Login.Action';

// hooks
import Iconify from '../../components/iconify';

import useIsMountedRef from '../../hooks/useIsMountedRef';

// ----------------------------------------------------------------------

CreateUserPasswordForm.propTypes = {
  onSent: PropTypes.func,
  onGetEmail: PropTypes.func,
};

export default function CreateUserPasswordForm({ onSent, onGetEmail }) {
  const isMountedRef = useIsMountedRef();
  const dispatch = useDispatch();
  const { email, code } = useParams();
  const { state } = useLocation();
  const { accessToken } = state;
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const GeneratePasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required('Current Password is required')
      .min(6, 'Password should be of minimum 6 characters length'),
    newpassword: Yup.string()
      .required('Password is required')
      .min(6, 'Password should be of minimum 6 characters length'),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('newpassword')], 'New Password and Confirm Password must match'),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      newpassword: '',
      confirmPassword: '',
    },
    validationSchema: GeneratePasswordSchema,
    onSubmit: (values, { setErrors, setSubmitting }) => {
      const data = Object.assign({}, values) ;
      delete data.confirmPassword;
      setSubmitting(true);
      dispatch(requestUpdatePassword(data, accessToken, onCreateUserPasswordSuccess, onCreatePasswordError));
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setSubmitting, setErrors } = formik;

  const onCreateUserPasswordSuccess = (data) => {
    if (isMountedRef.current) {
      setSubmitting(false);
      enqueueSnackbar('Password created succsessfully. Please login again.', {
        variant: 'success',
      });
      onSent(true);
    }
  };

  const onCreatePasswordError = (error) => {
    const message = error?.response?.data?.message || error?.message || 'Please try again.';
    setSubmitting(false);
    if (isMountedRef.current) {
      enqueueSnackbar(message, {
        variant: 'error',
      });
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            {...getFieldProps('password')}
            fullWidth
            autoComplete="on"
            label="Current Password"
            type={showOldPassword ? 'text' : 'password'}
            error={Boolean(touched.password && errors.password)}
            helperText={(touched.password && errors.password) || 'Password must be minimum 6+'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowOldPassword(!showOldPassword)} edge="end">
                    <Iconify icon={showOldPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            {...getFieldProps('newpassword')}
            fullWidth
            autoComplete="on"
            type={showNewPassword ? 'text' : 'password'}
            label="New Password"
            error={Boolean(touched.newpassword && errors.newpassword)}
            helperText={(touched.newpassword && errors.newpassword) || 'Password must be minimum 6+'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowNewPassword(!showNewPassword)} edge="end">
                    <Iconify icon={showNewPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            {...getFieldProps('confirmPassword')}
            fullWidth
            autoComplete="on"
            type={showConfirmPassword ? 'text' : 'password'}
            label="Confirm Password"
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={(touched.confirmPassword && errors.confirmPassword) || 'Password must be minimum 6+'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                    <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Save
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
