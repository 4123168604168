import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import mainRootReducer from "../slices/RootReducer";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import storage from "redux-persist-indexeddb-storage";
import rootSaga from "../sagas/RootSaga";
import createSagaMiddleware from "redux-saga";
import {
    persistReducer
} from "redux-persist";

export const appStorage = storage("esgwize_admin");

const persistConfig = {
    key: "root",
    version: 1,
    storage: appStorage,
    serialize: true, // Data serialization is not required and disabling it allows you to inspect storage value in DevTools
    stateReconciler: hardSet,
};

const persistedReducer = persistReducer(persistConfig, mainRootReducer);

let sagaMiddleware = createSagaMiddleware();
const logger = createLogger({
    // ...options
});

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware({
            thunk: false,
            immutableCheck: false,
            serializableCheck: false,
        }),
        sagaMiddleware
    ],
});

sagaMiddleware.run(rootSaga);

export default store;