import * as types from '../constants/ActionTypes';
import { put, takeLatest, call } from 'redux-saga/effects';
import ApiConstants from '../api/ApiConstants';
import { storeTemplateData, deleteTemplateInfo, changeTemplateApprovalStatus, changeTemplateActiveStatus } from 'src/slices/Template.Slice';
import API from '../api';

function* requestGetTemplateList(action) {
  const { onSuccess, onError } = action;
  try {
    const response = yield call(API.post, ApiConstants.LIST_TEMPLATES);
    const { data } = response.data;
    yield put(storeTemplateData({ data: data }));
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestAddTemplate(action) {
  const { payload, onSuccess, onError } = action;
  try {
    const response = yield call(API.post, ApiConstants.ADD_TEMPLATE, payload);
    const data = response.data;
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestDeleteTemplate(action) {
  const { templateId, onSuccess, onError } = action;
  try {
    const response = yield call(API.delete, ApiConstants.DELETE_TEMPLATE(templateId));
    yield put(deleteTemplateInfo({ templateUuid: templateId }));
    onSuccess(templateId);
  } catch (error) {
    onError(error);
  }
}

function* requestUploadTeamplate(action) {
  const { templateId, payload, onSuccess, onError } = action;
  try {
    const headers = { 'Content-Type': 'multipart/form-data' };
    const response = yield call(API.put, ApiConstants.UPLOAD_TEMPLATE(templateId), payload, headers);
    onSuccess(templateId);
  } catch (error) {
    onError(error);
  }
}

function* requestUpdateTemplateStatus(action) {
  const { templateId, payload, onSuccess, onError } = action;
  try {
    const response = yield call(API.put, ApiConstants.STATUS_UPDATE_TEMPLATE(templateId), payload);
    yield put(changeTemplateApprovalStatus({ templateId: templateId, approvalstatus: payload.approvalstatus }));
    onSuccess(templateId);
  } catch (error) {
    onError(error);
  }
}

function* requestViewTemplateScore(action) {
  const { templateId, payload, onSuccess, onError } = action;
  try {
    const response = yield call(API.get, ApiConstants.VIEW_TEMPLATE(templateId), payload);
    onSuccess(response.data);
  } catch (error) {
    onError(error);
  }
}

function* requestUpdateTemplateActiveStatus(action) {
  const { templateId, payload, onSuccess, onError } = action;
  try {
    const response = yield call(API.put, ApiConstants.UPDATE_TEMPLATE_ACTIVE_STATUS(templateId), payload);
    yield put(changeTemplateActiveStatus({ templateId: templateId, status: payload.status }));
    onSuccess(templateId);
  } catch (error) {
    onError(error);
  }
}

export function* watchTemplateSaga() {
  yield takeLatest(types.LIST_TEMPLATE_REQUEST, requestGetTemplateList),
    yield takeLatest(types.ADD_TEMPLATE_REQUEST, requestAddTemplate),
    yield takeLatest(types.DELETE_TEMPLATE_REQUEST, requestDeleteTemplate),
    yield takeLatest(types.UPLOAD_TEMPLATE_REQUEST, requestUploadTeamplate),
    yield takeLatest(types.UPDATE_TEMPLATE_STATUS_REQUEST, requestUpdateTemplateStatus),
    yield takeLatest(types.VIEW_TEMPLATE_REQUEST, requestViewTemplateScore),
    yield takeLatest(types.UPDATE_TEMPLATE_ACTIVE_STATUS_REQUEST, requestUpdateTemplateActiveStatus);

}
