export const LOGIN_REQUEST_INITIATED = 'LOGIN_REQUEST_INITIATED'
export const LOGIN_REQUEST_PENDING = 'LOGIN_REQUEST_PENDING'
export const LOGIN_REQUEST_FULFILLED = 'LOGIN_REQUEST_FULFILLED'
export const LIST_CLIENT_REQUEST_INITIATED = "LIST_CLIENT_REQUEST"
export const LIST_CLIENT_REQUEST_PENDING = "LIST_CLIENT_REQUEST_PENDING"
export const ADD_CLIENT_REQUEST_INITIATED = "ADD_CLIENT_REQUEST_INITIATED"
export const ADD_CLIENT_REQUEST_PENDING = "ADD_CLIENT_REQUEST_PENDING"
export const LIST_USER_REQUEST_INITIATED = "LIST_USER_REQUEST"
export const LIST_USER_REQUEST_PENDING = "LIST_USER_REQUEST_PENDING"
export const ADD_USER_REQUEST_INITIATED = "ADD_USER_REQUEST_INITIATED"
export const ADD_USER_REQUEST_PENDING = "ADD_USER_REQUEST_PENDING"
export const LOGOUT_REQUEST = "LOGOUT_REQUEST"
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORDR_REQUEST"
export const GENERATE_PASSWORD_REQUEST= "GENERATE_PASSWORD_REQUEST"
export const UPDATE_PASSWORD_REQUEST= "UPDATE_PASSWORD_REQUEST"
export const REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST"
export const DELETE_CLIENT_REQUEST = "DELETE_CLIENT_REQUEST"
export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST "
export const EDIT_CLIENT_REQUEST = "EDIT_CLIENT_REQUEST"
export const UPDATE_CLIENT_STATUS_REQUEST = "UPDATE_CLIENT_STATUS_REQUEST"
export const EDIT_USER_REQUEST = "EDIT_USER_REQUEST"
export const UPDATE_USER_STATUS_REQUEST = "UPDATE_USER_STATUS_REQUEST"
export const USER_FILTER_REQUEST = "USER_FILTER_REQUEST"
export const MY_ACCOUNT_REQUEST = 'MY_ACCOUNT_REQUEST'

//Dashboard
export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST"

//News
export const ADD_NEWS_REQUEST = "ADD_NEWS_REQUEST"
export const NEWS_LIST_REQUEST = "NEWS_LIST_REQUEST"
export const EDIT_NEWS_REQUEST = "EDIT_NEWS_REQUEST"
export const DELETE_NEWS_REQUEST = "DELETE_NEWS_REQUEST"
export const UPDATE_NEWS_STATUS_REQUEST = "UPDATE_NEWS_STATUS_REQUEST"
export const NEWS_FILTER_REQUEST = "NEWS_FILTER_REQUEST"

//Portfolio
export const ADD_PORTFOLIO_REQUEST = "ADD_PORTFOLIO_REQUEST"
export const LIST_PORTFOLIO_REQUEST = "LIST_PORTFOLIO_REQUEST"
export const EDIT_PORTFOLIO_REQUEST = "EDIT_PORTFOLIO_REQUEST"
export const DELETE_PORTFOLIO_REQUEST = "DELETE_PORTFOLIO_REQUEST"
export const UPDATE_PORTFOLIO_STATUS_REQUEST = "UPDATE_PORTFOLIO_STATUS_REQUEST"
export const VIEW_PORTFOLIO_REQUEST = "VIEW_PORTFOLIO_REQUEST"
export const ARCHIVE_PORTFOLIO_REQUEST = "ARCHIVE_PORTFOLIO_REQUEST"
export const PORTFOLIO_ACTIVE_STATUS_REQUEST = "PORTFOLIO_ACTIVE_STATUS_REQUEST"


//Template
export const ADD_TEMPLATE_REQUEST = "ADD_TEMPLATE_REQUEST"
export const LIST_TEMPLATE_REQUEST = "LIST_TEMPLATE_REQUEST"
export const UPLOAD_TEMPLATE_REQUEST = "UPLOAD_TEMPLATE_REQUEST"
export const DELETE_TEMPLATE_REQUEST = "DELETE_TEMPLATE_REQUEST"
export const UPDATE_TEMPLATE_STATUS_REQUEST = "UPDATE_TEMPLATE_STATUS_REQUEST"
export const VIEW_TEMPLATE_REQUEST = "VIEW_TEMPLATE_REQUEST"
export const UPDATE_TEMPLATE_ACTIVE_STATUS_REQUEST = "UPDATE_TEMPLATE_ACTIVE_STATUS_REQUEST"


//Company Listing
export const LIST_COMPANY_REQUEST = "LIST_COMPANY_REQUEST"
export const ADD_COMPANY_REQUEST = "ADD_COMPANY_REQUEST"
export const UPDATE_COMPANY_REQUEST = "UPDATE_COMPANY_REQUEST"
export const STATUS_UPDATE_COMPANY_REQUEST = "STATUS_UPDATE_COMPANY_REQUEST"
export const DELETE_COMPANY_REQUEST = "DELETE_COMPANY_REQUEST"
export const COMPANY_WISE_CLIENT_REQUEST = "COMPANY_WISE_CLIENT_REQUEST"
export const STATUS_UPDATE_COMPANY_CLIENT_REQUEST = "STATUS_UPDATE_COMPANY_CLIENT_REQUEST"
export const COMPANY_FILTER_REQUEST = "COMPANY_FILTER_REQUEST"
export const ACTIVE_COMPANY_LIST = "ACTIVE_COMPANY_LIST"

//Company Client Wise
export const COMPANY_BULK_UPLOAD_QUESTION = "COMPANY_BULK_UPLOAD_QUESTION"
export const DATA_FILE_READER_REQUEST = "DATA_FILE_READER_REQUEST"
export const SAVE_QUESTION_DRAFT_REQUEST = "SAVE_QUESTION_DRAFT_REQUEST"
export const APPROVE_REJECT_QUESTION_REQUEST = "APPROVE_REJECT_QUESTION_REQUEST"
export const SUPPORTING_FILE_LIST_REQUEST = "SUPPORTING_FILE_LIST_REQUEST"
export const UPLOAD_SUPPORTING_FILE_REQUEST = "UPLOAD_SUPPORTING_FILE_REQUEST"

// Common Actions
export const BULK_UPLOAD_REQUEST = "BULK_UPLOAD_REQUEST"
export const FILTER_REQUEST = "FILTER_REQUEST"

// Materiality Metrics
export const MATERIALITY_METRICS_REQUEST = 'MATERIALITY_METRICS_REQUEST'
export const LIST_DATAPOINTS_REQUEST = "LIST_DATAPOINTS_REQUEST"

export const ESG_RULES_REQQUEST = "ESG_RULES_REQQUEST"

export const LIST_DEFAULT_CLIENT_REQUEST = "LIST_DEFAULT_CLIENT_REQUEST"