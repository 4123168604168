function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';


export const PATH_AUTH = {
    login: path(ROOTS_AUTH, '/login'),
    forgot_password: path(ROOTS_AUTH, '/forgot-password'),
    generate_password: path(ROOTS_AUTH, '/generate-password'),
    create_user_password: path(ROOTS_AUTH, '/createUserPassword'),

}


export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    general: {
        user_list: path(ROOTS_DASHBOARD, '/user'),
        add_user: path(ROOTS_DASHBOARD, '/user/add'),
        edit_user: path(ROOTS_DASHBOARD, '/user/edit-user'),
        portfolio_list: path(ROOTS_DASHBOARD, '/portfolio'),
        add_portfolio: path(ROOTS_DASHBOARD, '/portfolio/add'),
        edit_portfolio: path(ROOTS_DASHBOARD, '/portfolio/edit'),
        template_list: path(ROOTS_DASHBOARD, '/corporate/template'),
        add_template: path(ROOTS_DASHBOARD, '/corporate/template/add'),
        company_list: path(ROOTS_DASHBOARD, '/company'),
        add_company: path(ROOTS_DASHBOARD, '/company/add'),
        edit_company: path(ROOTS_DASHBOARD, '/company/edit'),
        company_client: path(ROOTS_DASHBOARD, '/company/clients'),
        company_news: path(ROOTS_DASHBOARD, '/news'),
        company_news_add: path(ROOTS_DASHBOARD, '/news/add'),
        company_news_edit: path(ROOTS_DASHBOARD, '/news/edit'),
        template_score: path(ROOTS_DASHBOARD, '/corporate/template/view_score'),
        view_portfolio: path(ROOTS_DASHBOARD, '/portfolio/view'),
        supportingFileList: path(ROOTS_DASHBOARD, '/company/supportingfiles'),
        materiality_metrics: path(ROOTS_DASHBOARD, '/esg/metrics'),
        dataPoints: path(ROOTS_DASHBOARD, '/esg/dataPoints'),
        esgrules: path(ROOTS_DASHBOARD, '/corporate/esgrules')
    },
    client: {
        root: path(ROOTS_DASHBOARD, '/client'),
        add_client: path(ROOTS_DASHBOARD, '/client/add'),
        edit_client: path(ROOTS_DASHBOARD, '/client/edit-client')
    }
}