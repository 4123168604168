import PropTypes from 'prop-types';
// @mui
import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Rating,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
  FormControl,
  ListItemText,
  Theme,
  useTheme,
  TextField,
  InputLabel,
  OutlinedInput,
  Chip,
  Autocomplete,
} from '@mui/material';
// components
import Iconify from '../../../components/iconify';
import { sentenceCase } from 'change-case';
import Scrollbar from '../../../components/scrollbar';
import { ColorMultiPicker } from '../../../components/color-utils';
import { useEffect, useState } from 'react';
import { CheckBox, RollerShadesClosedRounded } from '@mui/icons-material';
import useGetClientList from 'src/hooks/useGetClientList';
import { ROLES } from '../../../constants/AppConstants';
// ----------------------------------------------------------------------
export const FILTER_STATUS_OPTIONS = ['pending', 'approved', 'rejected'];

// ----------------------------------------------------------------------

UserFilterSidebar.propTypes = {
  openFilter: PropTypes.bool,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
};

export default function UserFilterSidebar({ openFilter, onOpenFilter, onCloseFilter, onFilter }) {
  const { dropDownData } = useGetClientList();

  const [clients, setClients] = useState([]);
  const [roles, setRoles] = useState([]);
  const [filterStatus, setFilterStatus] = useState([]);
  const [filters, setFilters] = useState({
    status: [],
    client: [],
    role: [],
  });

  const handleChange = (event, val) => {
    const {
      target: { value },
    } = event;
    const arr = val.map((item) => item.id)
    console.log('val', val);
    // setClients(typeof value === 'string' ? value.split(',') : arr);

    setFilters((prevFilter) => ({
      ...prevFilter,
      client: arr,
    }));
  };

  const handleRoleChange = (event) => {
    const {
      target: { value },
    } = event;

    setRoles(typeof value === 'string' ? value.split(',') : value);

    setFilters((prevFilter) => ({
      ...prevFilter,
      role: value,
    }));
  };

  const handleClearFilter = () => {
    setFilterStatus([]);
    setClients([]);
    setRoles([]);
    setFilters((prevFilter) => ({
      ...prevFilter,
      status: [],
      client: [],
      role: [],
    }));
  };

  const setStatus = (e) => {
    let fs = [];
    if (e.target.checked) {
      fs = filterStatus.includes(e.target.name) ? filterStatus : [...filterStatus, e.target.name];
    } else {
      fs = filterStatus.filter((name) => name !== e.target.name);
    }
    setFilterStatus(fs);
    setFilters((prevFilter) => ({
      ...prevFilter,
      status: fs,
    }));
  };

  const onApplyFilter = (e) => {
    onFilter(filters);
  };

  useEffect(() => {
    onApplyFilter(filters);
  }, [filters]);

  return (
    <>
      <Button disableRipple color="inherit" endIcon={<Iconify icon="ic:round-filter-list" />} onClick={onOpenFilter}>
        Filters&nbsp;
      </Button>

      <Drawer
        anchor="right"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: 280, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Filters
          </Typography>
          <IconButton onClick={onCloseFilter}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Status
              </Typography>
              <FormGroup>
                {FILTER_STATUS_OPTIONS.map((item) => (
                  <FormControlLabel
                    key={item}
                    control={<Checkbox checked={filterStatus.includes(item)} onClick={setStatus} name={item} />}
                    label={sentenceCase(item)}
                  />
                ))}
              </FormGroup>
            </div>
          </Stack>
          <Stack direction="column" spacing={3} sx={{ p: 3 }}>
            {/* <FormControl>
                <InputLabel htmlFor="client-native-simple">Client</InputLabel>
                <Select
                  labelId="client-native-simple"
                  id="demo-mutiple-checkbox"
                  multiple
                  value={clients}
                  name="first"
                  onChange={handleChange}
                  input={<OutlinedInput id="demo-mutiple-checkbox" label="Client" />}
                  renderValue={(selected) =>
                    dropDownData
                      .filter((name) => selected.includes(name.id))
                      .map((record) => record.name)
                      .join(', ')
                  }
                >
                  {dropDownData.map((name) => (
                    <MenuItem key={name.name} value={name.id}>
                      <Checkbox checked={clients.indexOf(name.id) > -1} />
                      <ListItemText primary={name.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            <FormControl sx={{ width: '100%' }}>
              <Autocomplete
                labelId="demo-single-client-label"
                id="demo-single-client"
                fullWidth
                multiple={true}
                // value={values.client}
                filterSelectedOptions
                options={dropDownData}
                getOptionLabel={(option) => option.name}
                onChange={handleChange}
                renderInput={(params) => (
                  <TextField {...params} label="Client" placeholder="Client" />
                )}
              />
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="role-native-simple">Role</InputLabel>
              <Select
                labelId="role-native-simple"
                id="demo-mutiple-role-checkbox"
                multiple
                value={roles}
                name="first"
                onChange={handleRoleChange}
                input={<OutlinedInput id="demo-mutiple-role-checkbox" label="Role" />}
                renderValue={(selected) =>
                  ROLES.filter((name) => selected.includes(name.value))
                    .map((record) => record.name)
                    .join(', ')
                }
              >
                {ROLES.map((name) => (
                  <MenuItem key={name.name} value={name.value}>
                    <Checkbox checked={roles.indexOf(name.value) > -1} />
                    <ListItemText primary={name.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Scrollbar>

        <Box sx={{ p: 3 }}>
          <Button
            onClick={handleClearFilter}
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            startIcon={<Iconify icon="ic:round-clear-all" />}
          >
            Clear All
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
