import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import store from './store/Store'
import LoadingScreen from './components/LoadingScreen';
import { persistStore } from 'redux-persist';
//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import { LicenseInfo } from '@mui/x-license-pro';
import { MUIX_LICENSE_KEY } from './constants/AppConstants'

LicenseInfo.setLicenseKey(MUIX_LICENSE_KEY);

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

let persistor = persistStore(store);

const onBeforeLift = () => ({});

root.render(
  <HelmetProvider>
    <ReduxProvider store={store}>
      <PersistGate loading={<LoadingScreen />} persistor={persistor} onBeforeLift={onBeforeLift}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </ReduxProvider >
  </HelmetProvider >
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
