import * as Yup from 'yup';
import { useCallback, useState, useEffect } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { Theme, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Card,
  Stack,
  OutlinedInput,
  TextField,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Switch,
  Typography,
  FormLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import isEmpty from 'lodash/isEmpty';
import useGetClientList from '../../../hooks/useGetClientList';
import moment from 'moment';
// import { ALPHA_NUMERIC_REGEX } from '../../../constants/AppConstants';

export const ALPHA_NUMERIC_REGEX = /^[a-zA-Z0-9 ]+$/;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

const companyList = [
  { name: '1', value: 'Communication Services' },
  { name: '2', value: 'Consumer Discretionary' },
  { name: '3', value: 'Consumer Staples' },
  { name: '4', value: 'Energy' },
  { name: '5', value: 'Financials' },
  { name: '6', value: 'Health care' },
  { name: '7', value: 'Industrials' },
  { name: '8', value: 'Information Technology' },
  { name: '9', value: 'Materials' },
  { name: '10', value: 'Real Estate' },
  { name: '11', value: 'Utilities' },
];

const TemplateForm = ({ data, onSubmitData, isLoading }) => {
  const theme = useTheme();
  const UpdateUserSchema = Yup.object().shape({
    name: Yup.string().required('Please enter valid Name'),
    code: Yup.string().required('Please enter valid Code'),
    description: Yup.string().required('Please enter valid Description'),
    sector: Yup.string().required('Please select valid Sector'),
    isAssociatedWithDefaultClient: Yup.boolean(),
    client: Yup.string().when('isAssociatedWithDefaultClient', {
      is: false,
      then: Yup.string().required('Please select valid Client'),
    }),
    clientName: Yup.string().when('isAssociatedWithDefaultClient', {
      is: false,
      then: Yup.string().required(),
    }),
  });

  const { dropDownData } = useGetClientList();

  const isPublicClient = isEmpty(data) ? false : data.clientName === 'ESGWise Public Default';

  // const [iSAssociateWithDefaultClient, setIsAssociateWithDefaultClient] = useState(isPublicClient);

  const [clientList, setClientList] = useState(dropDownData);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.name || '',
      code: data?.code || '',
      description: data?.description || '',
      sector: data?.sector || '',
      clientName: data?.clientName || '',
      client: data?.client || '',
      isAssociatedWithDefaultClient: data?.isAssociatedWithDefaultClient || false,
      status: data?.status || 'active',
      createdAt: data?.createdAt || '',
      created: data?.created || '',
    },

    validationSchema: UpdateUserSchema,
    onSubmit: (values, { setErrors, setSubmitting }) => {
      console.log('Data', values);
      const info = values;
      delete info['createdAt'];
      delete info['created'];
      onSubmitData(info);
    }
  });

  const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  const isDisabled = isEmpty(data) ? false : true;

  useEffect(() => {
    if (!isEmpty(data)) {
      const created_date = moment(data?.createdAt).format('DD-MM-YYYY');
      setFieldValue('createdAt', created_date);
    }
  }, []);

  const onSelecDropDown = useCallback(
    (e) => {
      const value = e.target.value;
      setFieldValue('sector', value);
    },
    [setFieldValue]
  );

  const handleChange = (event) => {
    const value = event.target.checked;
    // setIsAssociateWithDefaultClient(value);
    setFieldValue('isAssociatedWithDefaultClient', value);
  };

  const onSelectClientDropDown = useCallback(
    (e) => {
      if (values.isAssociatedWithDefaultClient) {
        setFieldValue('client', '');
        setFieldValue('clientName', '');
      } else {
        const value = e.target.value;
        setFieldValue('client', value);
        const client = clientList.find((item) => item.id === value);
        setFieldValue('clientName', client.name);
      }
    },
    [setFieldValue]
  );

  const handleStatusChange = (event) => {
    const value = event.target.checked ? 'active' : 'inactive';
    setFieldValue('status', value);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={{ xs: 2, md: 3 }}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    disabled={isDisabled}
                    label="Name"
                    {...getFieldProps('name')}
                    inputProps={{
                      maxLength: 50,
                    }}
                    // onKeyDown={(event) => {
                    //   if (!ALPHA_NUMERIC_REGEX.test(event.key)) {
                    //     event.preventDefault();
                    //   }
                    // }}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                  <TextField
                    fullWidth
                    disabled={isDisabled}
                    label="Code"
                    {...getFieldProps('code')}
                    inputProps={{
                      maxLength: 30,
                    }}
                    onKeyDown={(event) => {
                      if (!ALPHA_NUMERIC_REGEX.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    error={Boolean(touched.code && errors.code)}
                    helperText={touched.code && errors.code}
                  />
                </Stack>
                <FormControl sx={{ width: '100%' }}>
                  <TextField
                    fullWidth
                    label="Description"
                    disabled={isDisabled}
                    placeholder=""
                    {...getFieldProps('description')}
                    multiline={true}
                    maxRows={15}
                    inputProps={{
                      maxLength: 100,
                    }}
                    // onKeyDown={(event) => {
                    //   if (!ALPHA_NUMERIC_REGEX.test(event.key)) {
                    //     event.preventDefault();
                    //   }
                    // }}
                    variant="filled"
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </FormControl>

                <FormControl sx={{ width: '100%' }}>
                  <InputLabel id="demo-multiple-chip-label">Sector</InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    fullWidth
                    value={values.sector}
                    disabled={isDisabled}
                    onChange={onSelecDropDown}
                    input={<OutlinedInput id="select-multiple-chip" label="Sector" />}
                    error={Boolean(touched.sector && errors.sector)}
                    MenuProps={MenuProps}
                  >
                    {companyList.map((item) => (
                      <MenuItem key={item.name} value={item.value} style={getStyles(item.name, item.name, theme)}>
                        {item.value}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText sx={{ color: 'red' }}>{touched.sector && errors.sector}</FormHelperText>
                </FormControl>
                <Stack direction="row" spacing={2} alignItems="center" sx={{ mt: 3 }}>
                  <Typography>Associate with Default Client</Typography>
                  <Switch
                    disabled={isDisabled}
                    checked={values.isAssociatedWithDefaultClient}
                    color="primary"
                    onChange={handleChange}
                  />
                </Stack>
                {!values.isAssociatedWithDefaultClient && (
                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ mt: 3 }}>
                    <FormControl sx={{ width: '100%' }}>
                      <InputLabel id="demo-single-client-label">Client</InputLabel>
                      <Select
                        labelId="demo-single-client-label"
                        id="demo-single-client"
                        fullWidth
                        value={values.client}
                        disabled={isDisabled}
                        onChange={onSelectClientDropDown}
                        input={<OutlinedInput id="demo-single-client" label="Client" />}
                        error={Boolean(touched.client && errors.client)}
                        MenuProps={MenuProps}
                      >
                        {clientList.map((item) => (
                          <MenuItem key={item.id} value={item.id} style={getStyles(item.name, item.name, theme)}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText sx={{ color: 'red' }}>{touched.client && errors.client}</FormHelperText>
                    </FormControl>
                  </Stack>
                )}

                <FormLabel component="legend">Status</FormLabel>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography>In-Active</Typography>
                  <Switch
                    {...getFieldProps('status')}
                    checked={values.status === 'active'}
                    color="primary"
                    onChange={handleStatusChange}
                  />
                  <Typography>Active</Typography>
                </Stack>
              </Stack>
              {!isEmpty(data) && (
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ mt: 3 }}>
                  <TextField
                    fullWidth
                    disabled={isDisabled}
                    label="Created Date"
                    {...getFieldProps('createdAt')}
                    error={Boolean(touched.createdAt && errors.createdAt)}
                    helperText={touched.createdAt && errors.createdAt}
                  />
                  <TextField
                    fullWidth
                    disabled={isDisabled}
                    label="Created By"
                    {...getFieldProps('created')}
                    error={Boolean(touched.created && errors.created)}
                    helperText={touched.created && errors.created}
                  />
                </Stack>
              )}
              {isEmpty(data) && (
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <LoadingButton type="submit" variant="contained" loading={isLoading}>
                    Save Changes
                  </LoadingButton>
                </Box>
              )}
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

TemplateForm.propTypes = {
  data: PropTypes.object,
  onSubmitData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default TemplateForm;
