import { createSlice } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'

const userSlice = createSlice({
    name:'user',
    initialState: {
        user_list: [],
    },
    reducers: {
        storeUserData:(state, action) => {
            const { data } = action.payload
            state.user_list = data
        },
        deleteUserInfo: (state, action) => {
            const { userId }  = action.payload
            state.user_list = state.user_list.filter((item) => item.ID !== userId)
        },
        changeUserApprovalStatus: (state, action) => {
            const { userId, approvalstatus } = action.payload
            state.user_list = state.user_list.map((item, index) => {
                if (item.ID === userId) {
                    item["approvalstatus"] = approvalstatus
                }
                return item
            })
        }
    }
})

export const { storeUserData, deleteUserInfo, changeUserApprovalStatus } = userSlice.actions

export default userSlice.reducer