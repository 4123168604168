import React, { useState } from 'react'
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
// material
import { Box, Grid, Card, Stack, Switch, TextField, FormControlLabel, Typography, FormHelperText, Container } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CompanyForm from '../../sections/@dashboard/company/CompanyForm';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'src/routes/path';
import { useSelector } from 'react-redux';
import { getCompanyById } from '../../selectors/Company.Selector';
import useGetCompanyInfo from '../../hooks/useGetCompanyInfo';
import { useParams } from 'react-router-dom'


const EditCompany = () => {

    const { isUpdatingCompanyInfo, requestEditCompany } = useGetCompanyInfo()

    const { companyId } = useParams()

    const { data } = useSelector((state) => ({
        data: getCompanyById(state, companyId)
    }))

    const onSubmitData = (data) => {
        requestEditCompany(companyId, data)
    }

    return (
        <Container>
            <HeaderBreadcrumbs
                heading="Edit Company"
                links={[
                    { name: 'Home', href: PATH_DASHBOARD.root },
                    { name: 'Company List', href: PATH_DASHBOARD.general.company_list },
                    { name: 'Edit Company' }
                ]}
            />
            <CompanyForm onSubmitData={onSubmitData} isLoading={isUpdatingCompanyInfo.status} data={data}/>
        </Container>
    )
}

export default EditCompany