// routes
import Router from './routes/routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import store from './store/Store';
import LoadingScreen from './components/LoadingScreen';
import { persistStore } from 'redux-persist';
import { SnackbarProvider } from 'notistack';
import ModalProvider from 'mui-modal-provider';

// ----------------------------------------------------------------------

export default function App() {
  let persistor = persistStore(store);

  const onBeforeLift = () => ({});

  return (
        <SnackbarProvider
          dense
          maxSnack={5}
          // preventDuplicate
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <ThemeProvider>
            <ScrollToTop />
            <StyledChart />
            <ModalProvider>
              <Router />
            </ModalProvider>
          </ThemeProvider>
        </SnackbarProvider>
  );
}
