import * as types from '../constants/ActionTypes';
import { put, takeLatest, call } from 'redux-saga/effects';
import ApiConstants from '../api/ApiConstants';
import API from '../api';

function* requestBulkUpload(action) {
  const { payload, onSuccess, onError } = action;
  try {
    const headers = { 'Content-Type': 'multipart/form-data' };
    const response = yield call(API.post, ApiConstants.BULK_UPLOAD, payload, headers);
    const data = response.data;
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestGetFilteredRecord(action) {
  const { payload, onSuccess, onError } = action;
  
  try {
    const headers = { 'Content-Type': 'multipart/form-data' };
    const response = yield call(API.post, ApiConstants.FILTER_DATA_REQUEST, payload, headers);
    const { data } = response.data;
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}


export function* watchCommonSaga() {
  yield takeLatest(types.BULK_UPLOAD_REQUEST, requestBulkUpload);
  yield takeLatest(types.FILTER_REQUEST, requestGetFilteredRecord);
}
