import React, { useState } from 'react'
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { Box, Grid, Card, Stack, Switch, TextField, FormControlLabel, Typography, FormHelperText, Container } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddClient from 'src/sections/@dashboard/client/AddClient';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'src/routes/path';
import { useDispatch } from 'react-redux'
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { requestAddClient } from 'src/actions/Client.Action';
import { useNavigate } from 'react-router-dom';


const CreateClient = () => {
    const isMountedRef = useIsMountedRef();
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setSubmitting] = useState(false)
    const navigate = useNavigate()

    const onSubmitData = (data) => {
        const dict = data
        const bodyFormData = new FormData();
        delete dict["profileImage"]
        const keys = Object.keys(dict);
        for (let i = 0; i < keys.length; i += 1) {
            const key = keys[i]
            if (key === "photoURL") {
                bodyFormData.append("profileImage", dict[key]?.file);
            }
            else {
                bodyFormData.append(key, dict[key]);
            }
        }
        setSubmitting(true)
        dispatch(requestAddClient(bodyFormData, onSuccessAddClient, onErrorAddClient))
    }

    const onSuccessAddClient = (data) => {
        setSubmitting(false)
        enqueueSnackbar('Client created successfully', {
            variant: 'success'
        });
        navigate(PATH_DASHBOARD.client.root, { replace: true })
    }

    const onErrorAddClient = (error) => {
        setSubmitting(false)
        const message = error?.response?.data?.message || error?.message || "Please try again."
        enqueueSnackbar(message, {
            variant: 'error'
        });
    }

    return (
        <Container>
            <HeaderBreadcrumbs
                heading="Add Client"
                links={[
                    { name: 'Home', href: PATH_DASHBOARD.root },
                    { name: 'Client List', href: PATH_DASHBOARD.client.root },
                    { name: 'Add Client' }
                ]}
            />
            <AddClient onSubmitData={onSubmitData} isLoading={isSubmitting}/>
        </Container>
    )
}

export default CreateClient