import * as types from '../constants/ActionTypes';
import { put, takeLatest, call } from 'redux-saga/effects';
import ApiConstants from '../api/ApiConstants';
import { requestGetMaterialityMetrics } from '../actions/Esg.Action';
import API from '../api';

function* requestGetMaterialityMetricsData(action) {
  const { onSuccess, onError } = action;
  try {
    const response = yield call(API.get, ApiConstants.MATERIALITY_METRICS);
    const { data } = response.data;
    //   yield put(storeNewsData({ data: allData }))
    onSuccess(data);
  } catch (error) {
    onError(error);
  }
}

function* requestGetEsgRulesList(action) {
  const { onSuccess, onError, payload } = action;
  try {
    const response = yield call(API.post, ApiConstants.LIST_RULES, payload);
    const { data } = response.data;
    //   yield put(storeNewsData({ data: allData }))
    onSuccess(data);
  } catch (error) {
    onError(error);
  }
}

function* requestGetDataPoints(action) {
  const { onSuccess, onError, payload } = action;
  try {
    const response = yield call(API.post, ApiConstants.LIST_DATA_POINTS, payload);
    const { data } = response.data;
    //   yield put(storeNewsData({ data: allData }))
    onSuccess(data);
  } catch (error) {
    onError(error);
  }
}

export function* watchEsgSaga() {
  yield takeLatest(types.MATERIALITY_METRICS_REQUEST, requestGetMaterialityMetricsData),
    yield takeLatest(types.LIST_DATAPOINTS_REQUEST, requestGetDataPoints),
    yield takeLatest(types.ESG_RULES_REQQUEST, requestGetEsgRulesList);

}
