import React from 'react';

import './style.css';

function getInitials(name) {
  const [firstName, lastName] = name.split(' ');

  if (firstName && lastName) {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  }

  return firstName.charAt(0).toUpperCase();
}


const InitialsAvatar = ({name, className}) => {
  return (
    <>
      <div
        aria-label={name}
        role="img"
        className={className || 'initials-avatar'}
      >
        <div>{getInitials(name)}</div>
      </div>
    </>
  );
};

export default React.memo(InitialsAvatar);