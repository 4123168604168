import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

const AlertDialog = ({
  title,
  description,
  successTitle = 'Ok',
  destroyTitle = 'Cancel',
  onConfirm,
  onCancel,
  isConfirmDialog = false,
  ...props
}) => (
  <Dialog {...props}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{description}</DialogContentText>
    </DialogContent>
    <DialogActions>
      {isConfirmDialog && (
        <Button onClick={onCancel} color="primary">
          {destroyTitle}
        </Button>
      )}
      <Button onClick={onConfirm} color="primary">
        {successTitle}
      </Button>
    </DialogActions>
  </Dialog>
);

AlertDialog.PropTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isConfirmDialog: PropTypes.bool.isRequired,
  onConfirm: PropTypes.string.isRequired,
};

AlertDialog.PropTypes = {
  title: '',
  description: '',
  isConfirmDialog: false,
  onConfirm: () => {},
  onCancel: () => {},
};

export default AlertDialog;
