import { createSlice } from '@reduxjs/toolkit'

const newsSlice = createSlice({
    name:'news',
    initialState: {
        news_list: []
    },
    reducers: {
        storeNewsData:(state, action) => {
            const { data } = action.payload
            state.news_list = data
        },
        deleteNewsInfo: (state, action) => {
            const { newsId }  = action.payload
            state.news_list = state.news_list.filter((item) => item['news-id'] !== newsId)
        },
        changeNewsApprovalStatus: (state, action) => {
            const { newsId, approvalstatus }  = action.payload
            state.news_list = state.news_list.map((item, index) => {
                if (item['news-id'] === newsId) {
                    item["approvalstatus"] = approvalstatus
                }
                return item
            })
        }
    }
})

export const { storeNewsData, deleteNewsInfo, changeNewsApprovalStatus } = newsSlice.actions

export default newsSlice.reducer