import React, { useState } from 'react'
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
// material
import { Box, Grid, Card, Stack, Switch, TextField, FormControlLabel, Typography, FormHelperText, Container } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddUser from 'src/sections/@dashboard/user/AddUser';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'src/routes/path';
import { useDispatch } from 'react-redux'
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { requestEditUser } from 'src/actions/User.Action';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserById } from '../../selectors/User.Selector';

const EditUser = () => {
    const isMountedRef = useIsMountedRef();
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setSubmitting] = useState(false)
    const navigate = useNavigate()

    const { userId } = useParams()

    const { user } = useSelector((state) => ({
        user: getUserById(state, userId)
    }))

    const onSubmitData = (data) => {
        setSubmitting(true)
        dispatch(requestEditUser(userId, data, onSuccessEditUser, onErrorEditUser))
    }

    const onSuccessEditUser = (data) => {
        setSubmitting(false)
        enqueueSnackbar('User updated successfully', {
            variant: 'success'
        });
        navigate(`${PATH_DASHBOARD.general.user_list}`)
    }

    const onErrorEditUser = (error) => {
        setSubmitting(false)
        const message = error?.response?.data?.message || error?.message || "Please try again."
        enqueueSnackbar(message, {
            variant: 'error'
        });
    }

    return (
        <Container>
            <HeaderBreadcrumbs
                heading="Edit User"
                links={[
                    { name: 'Home', href: PATH_DASHBOARD.root },
                    { name: 'User List', href: PATH_DASHBOARD.general.user_list },
                    { name: 'Edit User' }
                ]}
            />
            <AddUser onSubmitData={onSubmitData} isLoading={isSubmitting} data={user}/>
        </Container>
    )
}

export default EditUser