import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { requestGetClientList, requestDeleteClient, requestUpdateClientStatus, requestGetDefaultClientList } from "src/actions/Client.Action";
import { getClientList, getClientDropDownList, getDefaultClientDropDownList } from "src/selectors/Client.Selector";
import { useSnackbar } from 'notistack';

const useGetClientList = () => {
    const [isFetching, setIsFetching] = useState(false)
    const [isDeletingClient, setIsDeleteingClient] = useState(false)
    const [isUpdatingClientStatus, setIsUpdatingClientStatus] = useState({ status:false, clientId: null })
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();

    const { data, dropDownData, dropDownClientData } = useSelector(state => ({
        data: getClientList(state),
        dropDownData: getClientDropDownList(state),
        dropDownClientData: getDefaultClientDropDownList(state)
    }))

    useEffect(() => {
        loadClientList()
        loadDefaultClientList()
    }, [])

    const loadClientList = () => {
        setIsFetching(true)
        dispatch(requestGetClientList(onGetClientListSuccess, onClientListError))
    }

    const loadDefaultClientList = () => {
        setIsFetching(true)
        dispatch(requestGetDefaultClientList(onGetClientListSuccess, onClientListError))
    }

    const reloadData = () => {
        loadClientList()
    }

    const onGetClientListSuccess = (data) => {
        setIsFetching(false)
    }

    const onClientListError = (error) => {
        setIsFetching(false)
    }

    const requestDeleteClientInfo = (clientId) => {
        setIsDeleteingClient(true)
        dispatch(requestDeleteClient(clientId, onDeleteClientSuccess, onDeleteClientError))
    }

    const onDeleteClientSuccess = (data) => {
        setIsDeleteingClient(false)
        enqueueSnackbar('Client deleted successfully', {
            variant: 'success'
        });
    }

    const onDeleteClientError = (error) => {
        setIsDeleteingClient(false)
        const message = error?.response?.data?.message || error?.message || 'Client deletion failed';
        enqueueSnackbar(message, {
            variant: 'error'
        });
    }

    const requestUpdateClientApprovalStatus = (clientId, payload) => {
        setIsUpdatingClientStatus({ status: true, clientId: clientId })
        dispatch(requestUpdateClientStatus(clientId, payload, onUpdateClientStatusSuccess, onUpdateClientStatusSuccessError))
    }

    const onUpdateClientStatusSuccess = (data) => {
        reloadData()
        setIsUpdatingClientStatus({ status: false, clientId: null })
        enqueueSnackbar('Client status updated successfully', {
            variant: 'success'
        });
    }

    const onUpdateClientStatusSuccessError = (error) => {
        setIsUpdatingClientStatus({ status: false, clientId: null })
        const message = error?.response?.data?.message || error?.message || 'Client status update failed';
        enqueueSnackbar(message, {
            variant: 'error'
        });
    }

    return {
        data,
        isFetching,
        reloadData,
        dropDownData,
        dropDownClientData,
        isDeletingClient,
        requestDeleteClientInfo,
        isUpdatingClientStatus,
        requestUpdateClientApprovalStatus
    }
}

export default useGetClientList