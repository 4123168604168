import * as types from '../constants/ActionTypes';
import { put, takeLatest, call } from 'redux-saga/effects';
import ApiConstants from '../api/ApiConstants';
import { storePortfolioData, deletePortfolioInfo, changePortfolioApprovalStatus, changePortfolioActivelStatus } from 'src/slices/Portfolio.Slice';
import API from '../api';

function* requestGetPortfolioList(action) {
  const { onSuccess, onError } = action;
  try {
    const response = yield call(API.get, ApiConstants.LIST_PORTFOLIO);
    const { data } = response.data;
    yield put(storePortfolioData({ data: data }));
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestAddPortfolio(action) {
  const { payload, onSuccess, onError } = action;
  try {
    const response = yield call(API.post, ApiConstants.ADD_PORTFOLIO, payload);
    const data = response.data;
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestEditPortfolio(action) {
  const { portfolioId, payload, onSuccess, onError } = action;
  try {
    const response = yield call(API.put, ApiConstants.PORTFOLIO_ACTIONS(portfolioId), payload);
    const data = response.data;
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestUpdatePortfolioStatus(action) {
  const { portfolioId, payload, onSuccess, onError } = action;
  try {
    const response = yield call(API.put, ApiConstants.STATUS_UPDATE_PORTFOLIO(portfolioId), payload);
    const data = response.data;
    yield put(changePortfolioApprovalStatus({ portfolioId: portfolioId, approvalstatus: payload.approvalstatus }));
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestDeletePortfolio(action) {
  const { portfolioId, onSuccess, onError } = action;
  try {
    const response = yield call(API.delete, ApiConstants.PORTFOLIO_ACTIONS(portfolioId));
    yield put(deletePortfolioInfo({ portfolioId }));
    onSuccess(portfolioId);
  } catch (error) {
    onError(error);
  }
}

function* requestViewPortfolioScore(action) {
  const { portfolioId, payload, onSuccess, onError } = action;
  try {
    const response = yield call(API.get, ApiConstants.VIEW_PORTFOLIO(portfolioId), payload);
    onSuccess(response.data);
  } catch (error) {
    onError(error);
  }
}

function* requestGetArchivePortfolioScore(action) {
  const { payload, onSuccess, onError } = action;
  try {
    const response = yield call(API.post, ApiConstants.ARCHIVE_PORTFOLIO, payload);
    onSuccess(response.data?.data);
  } catch (error) {
    onError(error);
  }
}

function* requestUpdatePortfolioActiveStatus(action) {
  const { portfolioId, payload, onSuccess, onError } = action;
  try {
    const response = yield call(API.put, ApiConstants.STATUS_UPDATE_PORTFOLIO(portfolioId), payload);
    const data = response.data;
    yield put(changePortfolioActivelStatus({ portfolioId: portfolioId, status: payload.status }));
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

export function* watchPortfolioSaga() {
  yield takeLatest(types.LIST_PORTFOLIO_REQUEST, requestGetPortfolioList),
    yield takeLatest(types.ADD_PORTFOLIO_REQUEST, requestAddPortfolio),
    yield takeLatest(types.EDIT_PORTFOLIO_REQUEST, requestEditPortfolio),
    yield takeLatest(types.DELETE_PORTFOLIO_REQUEST, requestDeletePortfolio),
    yield takeLatest(types.UPDATE_PORTFOLIO_STATUS_REQUEST, requestUpdatePortfolioStatus),
    yield takeLatest(types.VIEW_PORTFOLIO_REQUEST, requestViewPortfolioScore),
    yield takeLatest(types.ARCHIVE_PORTFOLIO_REQUEST, requestGetArchivePortfolioScore),
    yield takeLatest(types.PORTFOLIO_ACTIVE_STATUS_REQUEST, requestUpdatePortfolioActiveStatus);

}
