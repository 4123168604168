import { createSelector } from 'reselect'
import isEmpty from 'lodash/isEmpty'

// Get Client List
const getClientData = state => state?.client?.client_list || []

export const getClientList = createSelector(
    [getClientData],
    (list) => list
)

// Get Client Dropdown data
const getClientDropDownData = (state) => {
    const arr_clients = getClientData(state)
    const filteredClients = arr_clients.filter((item) => (item.approvalstatus === "approved") && (item.status === "active") )
    return filteredClients.map((item) => {
        return {
            name: item.displayName,
            id: item.ID,
            mode: item.mode
        }
    })
}

// Get Default Client List
const getDefaultClientData = state => state?.client?.default_client_list || []


// Get Default Client Dropdown data
const getDefaultClientDropDownData = (state) => {
    const arr_clients = getDefaultClientData(state)
    const filteredClients = arr_clients.filter((item) => (item.approvalstatus === "approved") && (item.status === "active") )
    return filteredClients.map((item) => {
        return {
            name: item.displayName,
            id: item.ID,
            mode: item.mode
        }
    })
}

export const getClientDropDownList = createSelector(
    [getClientDropDownData],
    (list) => list
)

export const getDefaultClientDropDownList = createSelector(
    [getDefaultClientDropDownData],
    (list) => list
)

// Get Client By Id
function getClientDetail(arr, clientId) {
  const client = arr.find((item) => item.ID == clientId)
  return client
}

export const getClientById = createSelector(
    [
      // Usual first input - extract value from `state`
      state => getClientData(state),
      // Take the second arg, `clientId`, and forward to the output selector
      (state, clientId) => clientId
    ],
    // Output selector gets (`items, clientid)` as args
    (items, clientId) => getClientDetail(items, clientId)
  );