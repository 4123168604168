import { createSlice } from '@reduxjs/toolkit'

const companySlice = createSlice({
    name:'company',
    initialState: {
        company_list: [],
        company_wise_client_list: [],
        news_list: [],
    },
    reducers: {
        storeCompanyData:(state, action) => {
            const { data } = action.payload
            state.company_list = data
        },
        deleteCompanyInfo: (state, action) => {
            const { companyId }  = action.payload
            state.company_list = state.company_list.filter((item) => item.companyUuid !== companyId)
        },
        changeCompanyApprovalStatus: (state, action) => {
            const { companyId, approvalstatus }  = action.payload
            state.company_list = state.company_list.map((item, index) => {
                if (item.companyUuid === companyId) {
                    item["approvalstatus"] = approvalstatus
                }
                return item
            })
        },
        storeCompanyWiseClientlist:(state, action) => {
            const { data } = action.payload
            state.company_wise_client_list = data
        },
        clearCompanyWiseClientData:(state, action) => {
            state.company_wise_client_list = []
        },
        storeNewsData:(state, action) => {
            const { data } = action.payload
            state.news_list = data
        },
        deleteCompanyNewsInfo: (state, action) => {
            const { newsId }  = action.payload
            state.news_list = state.news_list.filter((item) => item["news-id"] !== newsId)
        },
    }
})

export const { storeCompanyData, deleteCompanyInfo, changeCompanyApprovalStatus, storeCompanyWiseClientlist, clearCompanyWiseClientData, storeNewsData, deleteCompanyNewsInfo } = companySlice.actions

export default companySlice.reducer