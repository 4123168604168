import * as types from '../constants/ActionTypes';
import { put, takeLatest, call } from 'redux-saga/effects';
import ApiConstants from '../api/ApiConstants';
import {
  storeCompanyData,
  changeCompanyApprovalStatus,
  deleteCompanyInfo,
  storeCompanyWiseClientlist,
  clearCompanyWiseClientData,
} from '../slices/Company.Slice';
import API from '../api';

function* requestGetCompanyList(action) {
  const { onSuccess, onError } = action;
  try {
    const response = yield call(API.get, ApiConstants.COMPANY_LIST);
    const { data } = response.data;
    yield put(storeCompanyData({ data }));
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestAddCompany(action) {
  const { payload, onSuccess, onError } = action;
  try {
    const response = yield call(API.post, ApiConstants.CREATE_COMPANY, payload);
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestUpdateCompanyInfo(action) {
  const { companyId, payload, onSuccess, onError } = action;
  try {
    const response = yield call(API.put, ApiConstants.UPDATE_COMPANY(companyId), payload);
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestUpdateCompanyStatusInfo(action) {
  const { companyId, payload, onSuccess, onError } = action;
  try {
    const response = yield call(API.put, ApiConstants.STATUS_UPDATE_COMPANY(companyId), payload);
    yield put(changeCompanyApprovalStatus({ companyId: companyId, approvalstatus: payload.approvalstatus }));

    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestDeleteCompany(action) {
  const { companyId, onSuccess, onError } = action;
  try {
    const response = yield call(API.delete, ApiConstants.DELETE_COMPANY(companyId));
    yield put(deleteCompanyInfo({ companyId }));
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestCompanyWiseClient(action) {
  const { companyCode, onSuccess, onError } = action;
  try {
    yield put(clearCompanyWiseClientData());
    const response = yield call(API.get, ApiConstants.COMPANY_WISE_CLIENT(companyCode));
    const { data } = response.data;
    yield put(storeCompanyWiseClientlist({ data }));
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestUpdateCompanyClientFileStatus(action) {
  const { payload, onSuccess, onError } = action;
  try {
    const response = yield call(API.post, ApiConstants.COMPANY_CLIENT_FILE_STATUS, payload);
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestCompanyBulkUploadQuestion(action) {
  const { payload, onSuccess, onError } = action;
  try {
    const headers = { 'Content-Type': 'multipart/form-data' };
    const response = yield call(API.post, ApiConstants.BULK_UPLOAD_QUESTION, payload, headers);
    onSuccess(response.data);
  } catch (error) {
    onError(error);
  }
}

function* requestDataQuestion(action) {
  const { payload, onSuccess, onError } = action;
  try {
    const response = yield call(API.post, ApiConstants.PUBLIC_UPLOADED, payload);
    onSuccess(response.data);
  } catch (error) {
    onError(error);
  }
}

function* requestSaveQuestionDraft(action) {
  const { payload, onSuccess, onError } = action;
  try {
    const response = yield call(API.post, ApiConstants.BULK_UPLOAD_QUESTION, payload);
    onSuccess(response.data);
  } catch (error) {
    onError(error);
  }
}

function* requestChangeQuestionStatus(action) {
  const { payload, onSuccess, onError } = action;
  try {
    const response = yield call(API.post, ApiConstants.BULK_UPLOAD_QUESTION, payload);
    onSuccess(payload);
  } catch (error) {
    onError(error);
  }
}

function* requestGetFilteredRecord(action) {
  const { payload, onSuccess, onError } = action;

  try {
    const response = yield call(API.post, ApiConstants.COMPANY_FILTER, payload);
    const { data } = response.data;
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestGetSupportingFilelist(action) {
  const { companyCode, clientId, onSuccess, onError } = action;
  try {
    const response = yield call(API.get, ApiConstants.SUPPORTING_DATA_FILES(companyCode, clientId));
    const { data } = response.data;
    onSuccess(data);
  } catch (error) {
    onError(error);
  }
}

function* requestUploadSupportingFilelist(action) {
  const { payload, onSuccess, onError } = action;
  try {
    const headers = { 'Content-Type': 'multipart/form-data' };
    const response = yield call(API.post, ApiConstants.UPLOAD_SUPPORTING_DATA_FILES, payload, headers);
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestGetActiveCommpany(action) {
  const { payload, onSuccess, onError } = action;
  try {
    const response = yield call(API.post, ApiConstants.ACTIVE_COMPANY_LIST, payload);
    const { data } = response.data;
    onSuccess(data);
  } catch (error) {
    onError(error);
  }
}

export function* watchCompanySaga() {
  yield takeLatest(types.LIST_COMPANY_REQUEST, requestGetCompanyList),
    yield takeLatest(types.ADD_COMPANY_REQUEST, requestAddCompany),
    yield takeLatest(types.UPDATE_COMPANY_REQUEST, requestUpdateCompanyInfo),
    yield takeLatest(types.STATUS_UPDATE_COMPANY_REQUEST, requestUpdateCompanyStatusInfo),
    yield takeLatest(types.DELETE_COMPANY_REQUEST, requestDeleteCompany),
    yield takeLatest(types.COMPANY_WISE_CLIENT_REQUEST, requestCompanyWiseClient),
    yield takeLatest(types.STATUS_UPDATE_COMPANY_CLIENT_REQUEST, requestUpdateCompanyClientFileStatus),
    yield takeLatest(types.COMPANY_BULK_UPLOAD_QUESTION, requestCompanyBulkUploadQuestion),
    yield takeLatest(types.DATA_FILE_READER_REQUEST, requestDataQuestion),
    yield takeLatest(types.SAVE_QUESTION_DRAFT_REQUEST, requestSaveQuestionDraft);
  yield takeLatest(types.APPROVE_REJECT_QUESTION_REQUEST, requestChangeQuestionStatus),
    yield takeLatest(types.COMPANY_FILTER_REQUEST, requestGetFilteredRecord),
    yield takeLatest(types.SUPPORTING_FILE_LIST_REQUEST, requestGetSupportingFilelist),
    yield takeLatest(types.UPLOAD_SUPPORTING_FILE_REQUEST, requestUploadSupportingFilelist),
    yield takeLatest(types.ACTIVE_COMPANY_LIST, requestGetActiveCommpany);

}
