import React, { useState } from 'react'
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
// material
import CompanyForm from 'src/sections/@dashboard/company/CompanyForm';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'src/routes/path';
import { useDispatch } from 'react-redux'
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { requestAddTemplate } from 'src/actions/Template.Action';
import { useNavigate } from 'react-router-dom';
import useGetCompanyInfo from '../../hooks/useGetCompanyInfo';
import { Container } from '@mui/material';


const CreateCompany = () => {

    const { isCreatingCompanyInfo, requestCreateCompanyInfo } = useGetCompanyInfo()

    const onSubmitData = (data) => {
        requestCreateCompanyInfo(data)
    }

    return (
        <Container>
            <HeaderBreadcrumbs
                heading="Add Company"
                links={[
                    { name: 'Home', href: PATH_DASHBOARD.root },
                    { name: 'Company List', href: PATH_DASHBOARD.general.company_list },
                    { name: 'Add Company' }
                ]}
            />
            <CompanyForm onSubmitData={onSubmitData} isLoading={isCreatingCompanyInfo}/>
        </Container>
    )
}

export default CreateCompany