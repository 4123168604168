import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isUserLoggedIn, getUserInfo } from '../selectors/Login.Selector';
import LoginPage from 'src/pages/Auth/LoginPage';
import CreateUserPassword from 'src/pages/Auth/CreateUserPassword';
import { PATH_DASHBOARD } from '../routes/path';

const AuthGuard = ({ children }) => {
  const { isAuthenticated, user } = useSelector((state) => ({
    isAuthenticated: isUserLoggedIn(state),
    user: getUserInfo(state),
  }));

  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <LoginPage />;
  } 

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
