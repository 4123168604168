import * as types from '../constants/ActionTypes';

export const requestGetClientList = (onSuccess, onError) => ({
    type: types.LIST_CLIENT_REQUEST_INITIATED,
    onSuccess,
    onError
})

export const requestAddClient = (payload, onSuccess, onError) => ({
    type: types.ADD_CLIENT_REQUEST_INITIATED,
    payload,
    onSuccess,
    onError
})

export const requestDeleteClient = (clientId, onSuccess, onError) => ({
    type: types.DELETE_CLIENT_REQUEST,
    clientId,
    onSuccess,
    onError
})

export const requestEditClient = (clientId, payload, onSuccess, onError) => ({
    type: types.EDIT_CLIENT_REQUEST,
    clientId,
    payload,
    onSuccess,
    onError
})

export const requestUpdateClientStatus = (clientId, payload, onSuccess, onError) => ({
    type: types.UPDATE_CLIENT_STATUS_REQUEST,
    clientId,
    payload,
    onSuccess,
    onError
})

export const requestGetDefaultClientList = (onSuccess, onError) => ({
    type: types.LIST_DEFAULT_CLIENT_REQUEST,
    onSuccess,
    onError
})

