import { createSlice } from '@reduxjs/toolkit'

const templateSlice = createSlice({
    name:'template',
    initialState: {
        template_list: [],
    },
    reducers: {
        storeTemplateData:(state, action) => {
            const { data } = action.payload
            state.template_list = data
        },
        deleteTemplateInfo: (state, action) => {
            const { templateUuid }  = action.payload
            state.template_list = state.template_list.filter((item) => item.templateUuid !== templateUuid)
        },
        changeTemplateApprovalStatus: (state, action) => {
            const { templateId, approvalstatus } = action.payload
            state.template_list = state.template_list.map((item, index) => {
                if (item.templateUuid === templateId) {
                    item["approvalstatus"] = approvalstatus
                }
                return item
            })
        },
        changeTemplateActiveStatus: (state, action) => {
            const { templateId, status } = action.payload
            state.template_list = state.template_list.map((item, index) => {
                if (item.templateUuid === templateId) {
                    item["status"] = status
                }
                return item
            })
        }
    }
})

export const { storeTemplateData, deleteTemplateInfo, changeTemplateApprovalStatus, changeTemplateActiveStatus } = templateSlice.actions

export default templateSlice.reducer