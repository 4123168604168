import * as types from '../constants/ActionTypes';

export const requestGetTemplateList = (payload, onSuccess, onError) => ({
    type: types.LIST_TEMPLATE_REQUEST,
    payload,
    onSuccess,
    onError
})

export const requestAddTemplate = (payload, onSuccess, onError) => ({
    type: types.ADD_TEMPLATE_REQUEST,
    payload,
    onSuccess,
    onError
})

export const requestDeleteTemplate = (templateId, onSuccess, onError) => ({
    type: types.DELETE_TEMPLATE_REQUEST,
    templateId,
    onSuccess,
    onError
})

export const requestUploadTemplate = (templateId, payload, onSuccess, onError) => ({
    type: types.UPLOAD_TEMPLATE_REQUEST,
    templateId,
    payload,
    onSuccess,
    onError
})

export const requestUpdateTemplateStatus = (templateId, payload, onSuccess, onError) => ({
    type: types.UPDATE_TEMPLATE_STATUS_REQUEST,
    templateId,
    payload,
    onSuccess,
    onError
})

export const requestViewTemplateScore = (templateId, onSuccess, onError) => ({
    type: types.VIEW_TEMPLATE_REQUEST,
    templateId,
    onSuccess,
    onError
})

export const requestUpdateTemplateActiveStatus = (templateId, payload, onSuccess, onError) => ({
    type: types.UPDATE_TEMPLATE_ACTIVE_STATUS_REQUEST,
    templateId,
    payload,
    onSuccess,
    onError
})


