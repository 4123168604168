import * as types from '../constants/ActionTypes'

export const requestAddPortfolio = (payload, onSuccess, onError) => ({
    type: types.ADD_PORTFOLIO_REQUEST,
    payload,
    onSuccess,
    onError
})

export const requestListPortfolio = (onSuccess, onError) => ({
    type: types.LIST_PORTFOLIO_REQUEST,
    onSuccess,
    onError
})

export const requestEditPortfolio = (portfolioId, payload, onSuccess, onError) => ({
    type: types.EDIT_PORTFOLIO_REQUEST,
    portfolioId,
    payload,
    onSuccess,
    onError
})

export const requestDeletePortfolio = (portfolioId, onSuccess, onError) => ({
    type: types.DELETE_PORTFOLIO_REQUEST,
    portfolioId,
    onSuccess,
    onError
})

export const requestUpdatePortfolioStatus = (portfolioId, payload, onSuccess, onError) => ({
    type: types.UPDATE_PORTFOLIO_STATUS_REQUEST,
    portfolioId,
    payload,
    onSuccess,
    onError
})

export const requestViewPortfolioScore = (portfolioId, onSuccess, onError) => ({
    type: types.VIEW_PORTFOLIO_REQUEST,
    portfolioId,
    onSuccess,
    onError
})

export const requestGetArchivePortfolio = (payload, onSuccess, onError) => ({
    type: types.ARCHIVE_PORTFOLIO_REQUEST,
    payload,
    onSuccess,
    onError
})

export const requestUpdatePortfolioActiveStatus = (portfolioId, payload, onSuccess, onError) => ({
    type: types.PORTFOLIO_ACTIVE_STATUS_REQUEST,
    portfolioId,
    payload,
    onSuccess,
    onError
})