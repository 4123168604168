import { createSelector } from 'reselect'
import isEmpty from 'lodash/isEmpty'

const getTemplateData = state => state?.template?.template_list

export const getTemplatetList = createSelector(
    [getTemplateData],
    (list) => list
)

// Get Template By Id
function getTemplateDetail(arr, templateUuid) {
    const user = arr.find((item) => item.templateUuid == templateUuid)
    return user
}

export const getTemplateById = createSelector(
    [
        // Usual first input - extract value from `state`
        state => getTemplateData(state),
        // Take the second arg, `clientId`, and forward to the output selector
        (state, templateUuid) => templateUuid
    ],
    // Output selector gets (`items, clientid)` as args
    (items, templateUuid) => getTemplateDetail(items, templateUuid)
);