import * as types from '../constants/ActionTypes';

export const requestGetUserList = (payload, onSuccess, onError) => ({
    type: types.LIST_USER_REQUEST_INITIATED,
    payload,
    onSuccess,
    onError
})

export const requestAddUser = (payload, onSuccess, onError) => ({
    type: types.ADD_USER_REQUEST_INITIATED,
    payload,
    onSuccess,
    onError
})

export const requestDeleteUser = (userId, onSuccess, onError) => ({
    type: types.DELETE_USER_REQUEST,
    userId,
    onSuccess,
    onError
})

export const requestEditUser = (userId, payload, onSuccess, onError) => ({
    type: types.EDIT_USER_REQUEST,
    userId,
    payload,
    onSuccess,
    onError
})

export const requestUpdateUserStatus = (userId, payload, onSuccess, onError) => ({
    type: types.UPDATE_USER_STATUS_REQUEST,
    userId,
    payload,
    onSuccess,
    onError
})

export const requestGetFilterRecords = (payload, onSuccess, onError) => ({
    type: types.USER_FILTER_REQUEST,
    payload,
    onSuccess,
    onError
});

