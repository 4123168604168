import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux'
import { isUserLoggedIn } from '../selectors/Login.Selector'
import { PATH_DASHBOARD } from '../routes/path';


const GuestGuard = ({ children }) => {

    const { isAuthenticated } = useSelector(state => ({
        isAuthenticated: isUserLoggedIn(state)
    }))

    if (isAuthenticated) {
        return <Navigate to={PATH_DASHBOARD.root} />;
    }

    return <>{children}</>;

}

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default GuestGuard