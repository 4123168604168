import * as types from '../constants/ActionTypes';
import { put, takeLatest, call } from 'redux-saga/effects';
import ApiConstants from '../api/ApiConstants';
import { storeNewsData, deleteNewsInfo } from 'src/slices/News.Slice';
import API from '../api';

function* requestNewsList(action) {
  const { payload, onSuccess, onError } = action;
  try {
    const response = yield call(API.post, ApiConstants.LIST_NEWS, payload);
    const { data } = response.data;
    const { allData } = data;
    yield put(storeNewsData({ data: allData }))
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestAddNews(action) {
  const { payload, onSuccess, onError } = action;
  try {
    const headers = { 'Content-Type': 'multipart/form-data' };
    const response = yield call(API.post, ApiConstants.ADD_NEWS, payload, headers);
    const data = response.data;
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestEditNews(action) {
  const { newsId, payload, onSuccess, onError } = action;
  try {
    const headers = { 'Content-Type': 'multipart/form-data' };
    const response = yield call(API.put, ApiConstants.EDIT_NEWS(newsId), payload, headers);
    const data = response.data;
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestDeleteNews(action) {
  const { newsId, onSuccess, onError } = action;
  try {
    const response = yield call(API.delete, ApiConstants.DELETE_NEWS(newsId));
    yield put(deleteNewsInfo({ newsId }))
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestUpdateNewsStatus(action) {
    const { newsId, payload, onSuccess, onError } = action;
    try {
      const response = yield call(API.put, ApiConstants.NEWS_STATUS_CHANGE(newsId), payload);
      const data = response.data;
      onSuccess({ data, payload });
    } catch (error) {
      onError(error);
    }
}
  
function* requestGetFilteredRecord(action) {
  const { payload, onSuccess, onError } = action;
  
  try {
    const response = yield call(API.post, ApiConstants.NEWS_FILTER, payload);
    const { data } = response.data;
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}


export function* watchNewsSaga() {
  yield takeLatest(types.NEWS_LIST_REQUEST, requestNewsList),
    yield takeLatest(types.ADD_NEWS_REQUEST, requestAddNews),
    yield takeLatest(types.EDIT_NEWS_REQUEST, requestEditNews),
    yield takeLatest(types.DELETE_NEWS_REQUEST, requestDeleteNews),
    yield takeLatest(types.UPDATE_NEWS_STATUS_REQUEST, requestUpdateNewsStatus);
  yield takeLatest(types.NEWS_FILTER_REQUEST, requestGetFilteredRecord);
}
