import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Helmet } from 'react-helmet-async/lib';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import {
  Container,
  Stack,
  Typography,
  Paper,
  Table,
  Checkbox,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Card,
  Popover,
  Button,
  MenuItem,
  TablePagination,
  IconButton,
} from '@mui/material';
import moment from 'moment';
import useGetCompanyInfo from '../../hooks/useGetCompanyInfo';
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { CompanyListToolBar, CompanyListHead } from '../../sections/@dashboard/company';
import { PATH_DASHBOARD } from 'src/routes/path';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import ConfirmationDialog from '../../components/ConfirmDialog';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';
import useAuth from '../../hooks/useAuth';
import { useModal } from 'mui-modal-provider';
import AttachmentUploadDialog from '../../components/AttachmentUploadDialog';
import AlertDialog from '../../components/AlertDialog';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { SAMPLE_COMPANY_BULK_UPLOAD_URL } from '../../constants/AppConstants';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignment: 'left' },
  { id: 'industry', label: 'Industry', alignment: 'center' },
  { id: 'country', label: 'Country', alignment: 'center' },
  { id: 'status', label: 'Status', alignment: 'center' },
  { id: 'created', label: 'Created by', alignment: 'center' },
  { id: 'updatedAt', label: 'Last Modified Date', alignment: 'center' },
  { id: 'approvalstatus', label: 'Approval Status', alignment: 'center' },
  { id: 'statuchange', label: 'Approved By', alignment: 'center' },
  { id: '', label: 'Actions', alignment: 'center' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  // stabilizedThis.sort((a, b) => {
  //   const order = comparator(a[0], b[0]);
  //   if (order !== 0) return order;
  //   return a[1] - b[1];
  // });
  if (query) {
    return filter(
      array,
      (_company) =>
        _company?.name?.toLowerCase().includes(query.toLowerCase()) ||
        _company?.code?.toLowerCase().includes(query.toLowerCase()) ||
        _company?.sector?.toLowerCase().includes(query.toLowerCase()) ||
        _company?.country?.toLowerCase().includes(query.toLowerCase())
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const ManageCompany = () => {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [filterState, setFilterState] = useState([]);

  const { showModal } = useModal();

  const {
    data,
    isFetching,
    reloadData,
    isDeletingCompany,
    deleteCompany,
    isUpdatingCompanyStatus,
    requestUpdateCompanyApprovalStatus,
    isBulkUploadingCompany,
    requestCreateCompanyInBulk,
    filterData,
    setCompanyFilter,
  } = useGetCompanyInfo();

  const modal = useRef(null);

  const deleteModal = useRef(null);

  const { user } = useAuth();

  const currentSelectedRow = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    modal.current &&
      modal.current.update({
        isSubmittingForm: isBulkUploadingCompany,
      });
    if (!isBulkUploadingCompany) {
      modal.current && modal.current.hide();
      modal.current && reloadData();
    }
  }, [isBulkUploadingCompany]);

  useEffect(() => {
    if (!isDeletingCompany) {
      deleteModal.current && deleteModal.current.hide();
    }
  }, [isDeletingCompany]);

  const handleOpenMenu = (event, row) => {
    setOpen(event.currentTarget);
    currentSelectedRow.current = row;
    console.log(currentSelectedRow.current);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const navigateToEditCompany = () => {
    navigate(`${PATH_DASHBOARD.general.edit_company}/${currentSelectedRow.current?.companyUuid}`);
  };

  const navivagetToCompanyWiseClient = () => {
    navigate(`${PATH_DASHBOARD.general.company_client}/${currentSelectedRow.current?.code}`);
  };

  const navivagetToCompanyNews = () => {
    navigate(`${PATH_DASHBOARD.general.company_news}/${currentSelectedRow.current?.code}`);
  };

  const handleFilters = (filter) => {
    setCompanyFilter(filter);
    setFilterState(filter);
  };

  const filteredCompany =
    filterState.status?.length || filterState.country?.length || filterState.industry?.length ? filterData : data;

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const filteredCompanies = applySortFilter(filteredCompany, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredCompanies.length && !!filterName;

  const onTapStatusChange = (companyId, status) => {
    setOpen(null);
    requestUpdateCompanyApprovalStatus(companyId, { approvalstatus: status });
  };

  const renderApprovalStatus = (approvalStatus, rowId, createBy) => {
    if (isUpdatingCompanyStatus.status === true && isUpdatingCompanyStatus.companyId === rowId) {
      return <CircularProgress />;
    } else if ((user.ID === createBy || user.role === 'makeradmin') && user.role !== 'superadmin') {
      return <strong>{sentenceCase(approvalStatus)}</strong>;
    } else {
      if (approvalStatus && approvalStatus === 'pending') {
        return (
          <Stack direction="row" spacing={1} justifyContent="center">
            <IconButton size="large" color="inherit" onClick={(event) => onTapStatusChange(rowId, 'approved')}>
              <CheckCircleIcon htmlColor="green" fontSize="medium" />
            </IconButton>
            <IconButton size="large" color="inherit" onClick={(event) => onTapStatusChange(rowId, 'rejected')}>
              <CancelIcon htmlColor="red" fontSize="medium" />
            </IconButton>
          </Stack>
        );
      } else {
        return <strong>{sentenceCase(approvalStatus)}</strong>;
      }
    }
  };

  const onTapBulkUpload = useCallback(() => {
    modal.current = showModal(AttachmentUploadDialog, {
      title: 'Upload Companies',
      description: 'Select valid file to create companies in bulk',
      isSubmittingForm: isBulkUploadingCompany,
      link: SAMPLE_COMPANY_BULK_UPLOAD_URL,
      onTapCancel: () => {
        modal.current.hide();
      },
      onSubmitData: (values) => {
        console.log(values);
        const bodyFormData = new FormData();
        bodyFormData.append('file', values.file);
        bodyFormData.append('type', 'company');
        requestCreateCompanyInBulk(bodyFormData);
      },
    });
  }, []);

  const renderApprovalStatusPopoverItem = () => {
    const row = currentSelectedRow.current;
    const approvalStatus = row?.approvalstatus;
    const createBy = row?.createBy;
    if (
      (user.role !== 'makeradmin' && approvalStatus !== 'pending' && user.ID !== createBy) ||
      (user.role === 'superadmin' && approvalStatus !== 'pending')
    ) {
      const statusText = approvalStatus === 'approved' ? 'Reject Company' : 'Approve Company';
      const status = approvalStatus === 'approved' ? 'rejected' : 'approved';
      const icon = approvalStatus === 'approved' ? 'eva:close-outline' : 'eva:checkmark-outline';
      return (
        <MenuItem onClick={() => onTapStatusChange(row?.companyUuid, status)}>
          <Iconify icon={icon} sx={{ mr: 2 }} />
          {statusText}
        </MenuItem>
      );
    }
    return null;
  };

  const renderEdit = () => {
    const row = currentSelectedRow.current;
    const approvalStatus = row?.approvalstatus;
    const createBy = row?.createBy;
    const onlyview = row?.onlyview || false;
    if (onlyview === true) {
      return (
        <MenuItem onClick={navigateToEditCompany}>
          <VisibilityIcon sx={{ mr: 1.4 }} htmlColor="gray" />
          View
        </MenuItem>
      );
    } else {
      return (
        <>
          <MenuItem onClick={navivagetToCompanyWiseClient}>
            <Iconify icon={'eva:people-fill'} sx={{ mr: 2 }} />
            Clients
          </MenuItem>
          <MenuItem onClick={navigateToEditCompany}>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Edit
          </MenuItem>
          {user.role === 'superadmin' && (
            <MenuItem onClick={onTapDelete}>
              <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
              Delete
            </MenuItem>
          )}
        </>
      );
    }
    return null;
  };

  const onTapDelete = () => {
    const row = currentSelectedRow.current;
    const companyUuid = row?.hasOwnProperty('companyUuid') && row['companyUuid'];

    deleteModal.current = showModal(AlertDialog, {
      title: 'Delete Company',
      description: 'Are you sure you want to delete Company?',
      successTitle: 'Yes',
      destroyTitle: 'No',
      onConfirm: () => {
        setOpen(null);
        deleteCompany(companyUuid);
        deleteModal.current.hide();
      },
      onCancel: () => {
        deleteModal.current.hide();
      },
      isConfirmDialog: true,
    });
  };

  return (
    <>
      <Container>
        <HeaderBreadcrumbs
          heading="Company Management"
          links={[{ name: 'Home', href: PATH_DASHBOARD.root }, { name: 'Company List' }]}
        />

        <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={5}>
          {/* <Typography variant="h4" gutterBottom>
            Company
          </Typography> */}
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <Button onClick={onTapBulkUpload} variant="contained" sx={{ mr: 5 }}>
              Bulk Upload
            </Button>
            <Button
              to={PATH_DASHBOARD.general.add_company}
              component={RouterLink}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add Company
            </Button>
          </Stack>
        </Stack>
        <Card>
          <CompanyListToolBar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            onFilter={handleFilters}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <CompanyListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={data.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredCompanies.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const {
                      companyUuid,
                      name,
                      code,
                      createBy,
                      sector,
                      country,
                      approvalstatus,
                      status,
                      created,
                      statuschange,
                      updatedAt,
                    } = row;
                    const selectedUser = selected.indexOf(name) !== -1;
                    const last_modified_date = moment(updatedAt).format('DD-MM-YYYY h:mm A');
                    const name_code = `${name}\n\n${code}`;
                    return (
                      <TableRow hover key={companyUuid} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        {/* <TableCell padding="checkbox">
                                                    <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                                                </TableCell> */}

                        {/* <TableCell component="th" scope="row" padding="none">
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <Typography variant="subtitle2" noWrap>
                                                            {displayName}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell> */}

                        <TableCell align="left">
                          <Stack direction="column">
                            <Typography variant="body2">{name}</Typography>
                            <Typography variant="body2">{code}</Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="center">{sector}</TableCell>

                        <TableCell align="center">{country}</TableCell>

                        <TableCell align="center">
                          <Label color={(status === 'inactive' && 'error') || 'success'}>{sentenceCase(status)}</Label>
                        </TableCell>

                        <TableCell align="center">{created}</TableCell>

                        <TableCell align="center">{last_modified_date}</TableCell>

                        <TableCell align="center">
                          {renderApprovalStatus(approvalstatus, companyUuid, createBy)}
                        </TableCell>

                        <TableCell align="center">{statuschange}</TableCell>

                        <TableCell align="center">
                          <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredCompanies.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 180,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {/* {renderApprovalStatusPopoverItem()} */}

        {renderEdit()}
      </Popover>
    </>
  );
};

export default ManageCompany;
