import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Card,
  Stack,
  Switch,
  TextField,
  FormControlLabel,
  Typography,
  FormHelperText,
  FormLabel,
  Button,
  Select,
  SelectChangeEvent,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import countries from 'src/utils/Countries';
import UploadAvatar from '../../../components/upload/UploadAvatar';
import { fData } from 'src/utils/formatNumber';
import MuiPhoneNumber from 'mui-phone-number';
import { ALPHA_NUMERIC_REGEX, ALPHA_REGEX } from '../../../constants/AppConstants';
import AlertDialog from '../../../components/AlertDialog';
import { useModal } from 'mui-modal-provider';
import 'yup-phone';
import { ASSET_TYPE, CURRENCY_CODES, REPORTING_FREQUENCY, CLIENT_TYPE } from '../../../constants/AppConstants';
import { isEmpty } from 'lodash';
import { Theme, useTheme } from '@mui/material/styles';
import moment from 'moment'

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

const AddClient = ({ data, onSubmitData, isLoading }) => {
  const UpdateUserSchema = Yup.object().shape({
    displayName: Yup.string().required('Please enter valid name'),
    contactperson: Yup.string().required('Please enter valid name'),
    clienttype: Yup.string().required('Please select valid client'),
    reportingcurrency: Yup.string().required('Please select valid currency'),
    reportingfrequency: Yup.string().required('Please select valid frequency'),
    email: Yup.string().required('Email is required').email('Please enter valid email'),
    assetType: Yup.array()
      .of(Yup.string())
      .min(1, 'Please select valid asset type')
      .required('Please select valid asset type'),
    phone: Yup.string().required('Please enter valid Phone Number'),
    addressline1: Yup.string().required('Please enter valid Address'),
    country: Yup.string().required('Please select valid Country'),
    city: Yup.string().required('Please enter valid City'),
    postcode: Yup.string().required('Please enter valid Postal Code'),
  });
  const theme = useTheme();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      displayName: data?.displayName || '',
      contactperson: data?.contactperson || '',
      email: data?.email || '',
      clienttype: data?.clienttype || '',
      reportingcurrency: data?.reportingcurrency || '',
      reportingfrequency: data?.reportingfrequency || '',
      clientprofile: data?.clientprofile || '',
      assetType: data?.assetType || [],
      photoURL: data?.profileImage || '',
      phone: data?.phone || '',
      country: data?.country || '',
      addressline1: data?.addressline1 || '',
      addressline2: data?.addressline2 || '',
      updatedAt: data?.updatedAt || '',
      city: data?.city || '',
      status: data?.status || 'active',
      state: data?.state || '',
      postcode: data?.postcode || '',
      profileImage: data?.profileImage || '',
    },

    validationSchema: UpdateUserSchema,
    onSubmit: (values, { setErrors, setSubmitting }) => {
      const info = values;
      delete info['updatedAt'];
      onSubmitData(info);
      console.log('Data', info);
    },
  });

  const { showModal } = useModal();

  const isDisabled = data?.onlyview || false;

  const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  useEffect(() => {
    if (!isEmpty(data)) {
      const updated_date = moment(data?.updatedAt).format('DD-MM-YYYY');
      setFieldValue('updatedAt', updated_date);
    }
  }, []);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setFieldValue('photoURL', {
          file: file,
          preview: URL.createObjectURL(file),
        });
      }
    },
    [setFieldValue]
  );

  const onChangePhonenumber = useCallback(
    (value) => {
      setFieldValue('phone', value);
    },
    [setFieldValue]
  );

  const handleChange = (event) => {
    const value = event.target.checked ? 'active' : 'inactive';
    setFieldValue('status', value);
  };

  const onTapDelete = useCallback(() => {
    const modal = showModal(AlertDialog, {
      title: 'Delete Profile Image',
      description: 'Are you sure you want to delete Profile Image?',
      successTitle: 'Yes',
      destroyTitle: 'No',
      isConfirmDialog: true,
      onConfirm: () => {
        setFieldValue('photoURL', '');
        setFieldValue('profileImage', '');
        modal.hide();
      },
      onCancel: () => {
        modal.hide();
      },
    });
  }, []);

  const onSelectClientTypeDropDown = useCallback(
    (e) => {
      const value = e.target.value;
      setFieldValue('clienttype', value);
    },
    [setFieldValue]
  );

  const onSelectCurrencyDropDown = useCallback(
    (e) => {
      const value = e.target.value;
      setFieldValue('reportingcurrency', value);
    },
    [setFieldValue]
  );

  const onSelectFrequencyDropDown = useCallback(
    (e) => {
      const value = e.target.value;
      setFieldValue('reportingfrequency', value);
    },
    [setFieldValue]
  );

  const onSelectAssetTypeDropDown = useCallback(
    (e, val) => {
      const value = e.target.value;
      // const arr = value.map((item) => item)
      console.log('Read More', value);
      setFieldValue('assetType', value);
    },
    [setFieldValue]
  );

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card sx={{ py: 10, px: 3, textAlign: 'center' }}>
              <UploadAvatar
                accept="image/*"
                file={values.photoURL}
                maxSize={5000000}
                disabled={isDisabled}
                onDrop={handleDrop}
                caption={
                  <>
                    {!isEmpty(values.photoURL) && (
                      <Button variant="outlined" onClick={onTapDelete}>
                        Delete
                      </Button>
                    )}

                    <Typography
                      variant="caption"
                      sx={{
                        mt: 2,
                        mx: 'auto',
                        display: 'block',
                        textAlign: 'center',
                        color: 'text.secondary',
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png, *.gif
                      <br /> max size of {fData(5000000)}
                    </Typography>
                  </>
                }
              />

              <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                {touched.photoURL && errors.photoURL}
              </FormHelperText>
              <FormLabel component="legend" sx={{ textAlign: 'center', mt: 6, mb: 1 }}>
                Client's Status
              </FormLabel>
              <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                <Typography fontSize="15px" fontWeight="500">
                  InActive
                </Typography>
                <Switch
                  {...getFieldProps('status')}
                  checked={values.status === 'active'}
                  color="primary"
                  disabled={isDisabled}
                  onChange={handleChange}
                />
                <Typography fontSize="15px" fontWeight="500">
                  Active
                </Typography>
              </Stack>
            </Card>
          </Grid>

          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={{ xs: 2, md: 3 }}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    label="Name"
                    disabled={isDisabled}
                    {...getFieldProps('displayName')}
                    inputProps={{
                      maxLength: 50,
                    }}
                    error={Boolean(touched.displayName && errors.displayName)}
                    helperText={touched.displayName && errors.displayName}
                  />
                  <TextField
                    fullWidth
                    label="Main Contact Name"
                    inputProps={{
                      maxLength: 30,
                    }}
                    disabled={isDisabled}
                    onKeyDown={(event) => {
                      if (!ALPHA_REGEX.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    {...getFieldProps('contactperson')}
                    error={Boolean(touched.contactperson && errors.contactperson)}
                    helperText={touched.contactperson && errors.contactperson}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  {/* <TextField fullWidth label="Phone Number" {...getFieldProps('phone')} /> */}
                  <TextField
                    fullWidth
                    label="Email Address"
                    disabled={isDisabled}
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-client-label">Client Type</InputLabel>
                    <Select
                      labelId="demo-country-label"
                      id="demo-country-chip"
                      fullWidth
                      disabled={isDisabled}
                      value={values.clienttype}
                      onChange={onSelectClientTypeDropDown}
                      input={<OutlinedInput id="select-client" label="Client Type" />}
                      error={Boolean(touched.clienttype && errors.clienttype)}
                      MenuProps={MenuProps}
                    >
                      {CLIENT_TYPE.map((item) => (
                        <MenuItem key={item.name} value={item.value} style={getStyles(item.name, item.value, theme)}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText sx={{ color: 'red' }}>{touched.clienttype && errors.clienttype}</FormHelperText>
                  </FormControl>
                </Stack>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <MuiPhoneNumber
                    fullWidth
                    defaultCountry={'us'}
                    variant="outlined"
                    disabled={isDisabled}
                    {...getFieldProps('phone')}
                    onChange={onChangePhonenumber}
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={touched.phone && errors.phone}
                  />
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-asset-type-label">Asset Type</InputLabel>
                    <Select
                      labelId="demo-asset-type-label"
                      id="demo-asset-chip"
                      fullWidth
                      multiple
                      disabled={isDisabled}
                      value={values.assetType}
                      onChange={onSelectAssetTypeDropDown}
                      input={<OutlinedInput id="select-asset-type" label="Asset Type" />}
                      error={Boolean(touched.assetType && errors.assetType)}
                      MenuProps={MenuProps}
                    >
                      {ASSET_TYPE.map((item) => (
                        <MenuItem key={item.name} value={item.value} style={getStyles(item.name, item.value, theme)}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText sx={{ color: 'red' }}>{touched.assetType && errors.assetType}</FormHelperText>
                  </FormControl>
                </Stack>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-reporting-currency-label">Reporting Currency</InputLabel>
                    <Select
                      labelId="demo-reporting-currency-label"
                      id="demo-currency-chip"
                      fullWidth
                      disabled={isDisabled}
                      value={values.reportingcurrency}
                      onChange={onSelectCurrencyDropDown}
                      input={<OutlinedInput id="select-client" label="Reporting Currency" />}
                      error={Boolean(touched.reportingcurrency && errors.reportingcurrency)}
                      MenuProps={MenuProps}
                    >
                      {CURRENCY_CODES.map((item) => (
                        <MenuItem key={item.name} value={item.value} style={getStyles(item.name, item.value, theme)}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText sx={{ color: 'red' }}>
                      {touched.reportingcurrency && errors.reportingcurrency}
                    </FormHelperText>
                  </FormControl>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-reporting-frequency-label">Reporting Frequency</InputLabel>
                    <Select
                      labelId="demo-reporting-frequency-label"
                      id="demo-frequency-chip"
                      fullWidth
                      disabled={isDisabled}
                      value={values.reportingfrequency}
                      onChange={onSelectFrequencyDropDown}
                      input={<OutlinedInput id="select-frequency" label="Reporting Currency" />}
                      error={Boolean(touched.reportingfrequency && errors.reportingfrequency)}
                      MenuProps={MenuProps}
                    >
                      {REPORTING_FREQUENCY.map((item) => (
                        <MenuItem key={item.name} value={item.value} style={getStyles(item.name, item.value, theme)}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText sx={{ color: 'red' }}>
                      {touched.reportingfrequency && errors.reportingfrequency}
                    </FormHelperText>
                  </FormControl>
                </Stack>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      fullWidth
                      label="Client Profile"
                      placeholder=""
                      disabled={isDisabled}
                      {...getFieldProps('clientprofile')}
                      multiline={true}
                      maxRows={15}
                      inputProps={{
                        maxLength: 100,
                      }}
                      variant="filled"
                    />
                  </FormControl>
                </Stack>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    disabled={isDisabled}
                    inputProps={{
                      maxLength: 100,
                    }}
                    label="Address 1"
                    {...getFieldProps('addressline1')}
                    onKeyDown={(event) => {
                      if (!ALPHA_NUMERIC_REGEX.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    error={Boolean(touched.addressline1 && errors.addressline1)}
                    helperText={touched.addressline1 && errors.addressline1}
                  />
                  <TextField
                    fullWidth
                    disabled={isDisabled}
                    inputProps={{
                      maxLength: 100,
                    }}
                    onKeyDown={(event) => {
                      if (!ALPHA_NUMERIC_REGEX.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    label="Address 2"
                    {...getFieldProps('addressline2')}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField
                    select
                    fullWidth
                    label="Country"
                    disabled={isDisabled}
                    placeholder="Country"
                    {...getFieldProps('country')}
                    SelectProps={{ native: true }}
                    error={Boolean(touched.country && errors.country)}
                    helperText={touched.country && errors.country}
                  >
                    <option value="" />
                    {countries.map((option) => (
                      <option key={option.code} value={option.label}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                  <TextField
                    fullWidth
                    disabled={isDisabled}
                    inputProps={{
                      maxLength: 20,
                    }}
                    label="State/Region"
                    {...getFieldProps('state')}
                    onKeyDown={(event) => {
                      if (!ALPHA_REGEX.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    error={Boolean(touched.state && errors.state)}
                    helperText={touched.state && errors.state}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    disabled={isDisabled}
                    inputProps={{
                      maxLength: 20,
                    }}
                    label="City"
                    {...getFieldProps('city')}
                    onKeyDown={(event) => {
                      if (!ALPHA_REGEX.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    error={Boolean(touched.city && errors.city)}
                    helperText={touched.city && errors.city}
                  />
                  <TextField
                    fullWidth
                    disabled={isDisabled}
                    inputProps={{
                      maxLength: 20,
                    }}
                    label="Zip/Code"
                    onKeyDown={(event) => {
                      if (!ALPHA_NUMERIC_REGEX.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    {...getFieldProps('postcode')}
                    error={Boolean(touched.postcode && errors.postcode)}
                    helperText={touched.postcode && errors.postcode}
                  />
                </Stack>
              </Stack>
              {!isEmpty(data) && (
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} mt={3}>
                  <FormControl sx={{ width: '100%' }}>
                    <TextField
                      fullWidth
                      label="Last Modified Date"
                      placeholder=""
                      disabled
                      {...getFieldProps('updatedAt')}
                    />
                  </FormControl>
                </Stack>
              )}
              {!isDisabled && (
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <LoadingButton type="submit" variant="contained" loading={isLoading}>
                    Save Changes
                  </LoadingButton>
                </Box>
              )}
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

AddClient.propTypes = {
  data: PropTypes.object,
  onSubmitData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default AddClient;
