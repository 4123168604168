import PropTypes from 'prop-types';
// @mui
import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Rating,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
  FormControl,
  ListItemText,
  Theme,
  useTheme,
  InputLabel,
  OutlinedInput,
  Chip,
} from '@mui/material';
// components
import { sentenceCase } from 'change-case';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import { ColorMultiPicker } from '../../../components/color-utils';
import { useEffect, useState } from 'react';
import { CheckBox } from '@mui/icons-material';
import useGetClientList from 'src/hooks/useGetClientList';
import countries from '../../../utils/Countries';
import { SECTOR_LIST } from '../../../constants/AppConstants';

// ----------------------------------------------------------------------
export const FILTER_STATUS_OPTIONS = ['pending', 'approved', 'rejected'];

// ----------------------------------------------------------------------

CompanyFilterSidebar.propTypes = {
  openFilter: PropTypes.bool,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
};

export default function CompanyFilterSidebar({ openFilter, onOpenFilter, onCloseFilter, onFilter }) {

  const [sector, setSector] = useState([]);
  const [country, setCountry] = useState([]);
  const [filterStatus, setFilterStatus] = useState([]);
  const [filters, setFilters] = useState({
    status: [],
    industry: [],
    country: [],
  });

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setSector(typeof value === 'string' ? value.split(',') : value);

    setFilters((prevFilter) => ({
      ...prevFilter,
      industry: value,
    }));
  };

  const handleCountryChange = (event) => {
    const {
      target: { value },
    } = event;

    setCountry(typeof value === 'string' ? value.split(',') : value);

    setFilters((prevFilter) => ({
      ...prevFilter,
      country: value,
    }));
  };

  const handleClearFilter = () => {
    setFilterStatus([]);
    setSector([]);
    setCountry([])
    setFilters((prevFilter) => ({
      ...prevFilter,
      status: [],
      industry: [],
      country: [],
    }));
  };

  const setStatus = (e) => {
    let fs = [];
    if (e.target.checked) {
      fs = filterStatus.includes(e.target.name) ? filterStatus : [...filterStatus, e.target.name];
    } else {
      fs = filterStatus.filter((name) => name !== e.target.name);
    }
    setFilterStatus(fs);
    setFilters((prevFilter) => ({
      ...prevFilter,
      status: fs,
    }));
  };

  const onApplyFilter = (e) => {
    console.log(filters);
    onFilter(filters);
  };

  useEffect(() => {
    onApplyFilter(filters);
  }, [filters]);

  return (
    <>
      <Button disableRipple color="inherit" endIcon={<Iconify icon="ic:round-filter-list" />} onClick={onOpenFilter}>
        Filters&nbsp;
      </Button>

      <Drawer
        anchor="right"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: 280, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Filters
          </Typography>
          <IconButton onClick={onCloseFilter}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Status
              </Typography>
              <FormGroup>
                {FILTER_STATUS_OPTIONS.map((item) => (
                  <FormControlLabel
                    key={item}
                    control={<Checkbox checked={filterStatus.includes(item)} onClick={setStatus} name={item} />}
                    label={sentenceCase(item)}
                  />
                ))}
              </FormGroup>
            </div>
          </Stack>
          <Stack direction="column" spacing={3} sx={{ p: 3 }}>
                <FormControl>
                  <InputLabel htmlFor="age-native-simple">Sector</InputLabel>
                  <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    value={sector}
                    name="first"
                    onChange={handleChange}
                    input={<OutlinedInput label="Sector" />}
                    renderValue={(selected) =>
                      SECTOR_LIST.filter((item) => selected.includes(item.value))
                        .map((record) => record.value)
                        .join(', ')
                    }
                  >
                    {SECTOR_LIST.map((name) => (
                      <MenuItem key={name.name} value={name.value}>
                        <Checkbox checked={sector.indexOf(name.value) > -1} />
                        <ListItemText primary={name.value} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel htmlFor="age-native-simple">Country</InputLabel>
                  <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    value={country}
                    name="first"
                    onChange={handleCountryChange}
                    input={<OutlinedInput label="Country" />}
                    renderValue={(selected) =>
                      countries
                        .filter((item) => selected.includes(item.label))
                        .map((record) => record.label)
                        .join(', ')
                    }
                  >
                    {countries.map((name) => (
                      <MenuItem key={name.code} value={name.label}>
                        <Checkbox checked={country.indexOf(name.label) > -1} />
                        <ListItemText primary={name.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
          </Stack>
        </Scrollbar>

        <Box sx={{ p: 3 }}>
          <Button
            onClick={handleClearFilter}
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            startIcon={<Iconify icon="ic:round-clear-all" />}
          >
            Clear All
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
