import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { TextField, Alert, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack';
import { requestForgotPassword } from '../../actions/Login.Action'
// hooks
import { PATH_AUTH } from 'src/routes/path';
import useIsMountedRef from '../../hooks/useIsMountedRef';

// ----------------------------------------------------------------------

ForgotPasswordForm.propTypes = {
  onSent: PropTypes.func,
  onGetEmail: PropTypes.func
};

export default function ForgotPasswordForm({ onSent, onGetEmail }) {
  const isMountedRef = useIsMountedRef();
  const dispatch = useDispatch()
  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required')
  });

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: ResetPasswordSchema,
    onSubmit:  (values, { setErrors, setSubmitting }) => {
        dispatch(requestForgotPassword(values.email, onForgotPasswordSuccess, onForgotPasswordError))
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setSubmitting, setErrors } = formik;

  const onForgotPasswordSuccess = (data) => {
    if (isMountedRef.current) {
        onSent();
        onGetEmail(formik.values.email);
        setSubmitting(false);
      }
  }

  const onForgotPasswordError = (error) => {
    if (isMountedRef.current) {
        setErrors({ afterSubmit: error.message });
        setSubmitting(false);
      }
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

          <TextField
            fullWidth
            {...getFieldProps('email')}
            type="email"
            label="Email address"
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Reset Password
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
