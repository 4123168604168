import { createSelector } from 'reselect'
import isEmpty from 'lodash/isEmpty'

const isAuthenticated = (state) => {
    const isUserLoggedIn = !isEmpty(state?.auth?.access_token)
    return isUserLoggedIn
}

export const isUserLoggedIn = createSelector(
    [isAuthenticated],
    (isLoggedIn) => isLoggedIn
)

const getLoginInfo = (state) => state?.auth?.user


export const getUserInfo = createSelector(
    [getLoginInfo],
    (info) => info
)
