import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Button, Container, Typography } from '@mui/material';
// layouts
// routes
import { PATH_AUTH } from '../../routes/path';
import Page from 'src/components/Page';
// components
import GeneratePasswordForm from 'src/sections/auth/GeneratePasswordForm';
import SentIcon from '../../components/icons/icons_sent';
import LogoOnlyLayout from 'src/components/LogoOnlyLayout';

const RootStyle = styled(Page)(({ theme }) => ({
    display: 'flex',
    minHeight: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(12, 0)
}));


export default function GeneratePassword() {
    const [email, setEmail] = useState('');
    const [sent, setSent] = useState(false);

    return (
        <RootStyle title="esgwise | Forgot Password">
            <LogoOnlyLayout/>
            <Container>
                <Box sx={{ maxWidth: 480, mx: 'auto' }}>
                    {!sent ? (
                        <>
                            <Typography variant="h3" paragraph>
                                Change Password
                            </Typography>
                            <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                                Please enter the verifictaion code sent on your email address associated with your account and new password.
                            </Typography>

                            <GeneratePasswordForm onSent={() => setSent(true)} onGetEmail={(value) => setEmail(value)} />

                            <Button fullWidth size="large" component={RouterLink} to={PATH_AUTH.login} sx={{ mt: 1 }}>
                                Back
                            </Button>
                        </>
                    ) : (
                        <Box sx={{ textAlign: 'center' }}>
                            <SentIcon sx={{ mb: 5, mx: 'auto', height: 160 }} />

                            <Typography variant="h3" gutterBottom>
                                Password changed successfully
                            </Typography>
                            <Typography>
                                Please login again
                            </Typography>

                            <Button size="large" variant="contained" component={RouterLink} to={PATH_AUTH.login} sx={{ mt: 5 }}>
                                Back
                            </Button>
                        </Box>
                    )}
                </Box>
            </Container>
        </RootStyle>
    );
}
