import { createSelector } from 'reselect'
import isEmpty from 'lodash/isEmpty'

const getPortfolioData = state => state?.portfolio?.portfolio_list

export const getPortfoliotList = createSelector(
    [getPortfolioData],
    (list) => list
)

// Get Portfolio By Id
function getPortfolioDetail(arr, portfolioId) {
    const user = arr.find((item) => item.portfolioId == portfolioId)
    return user
}

export const getPortfolioById = createSelector(
    [
        // Usual first input - extract value from `state`
        state => getPortfolioData(state),
        // Take the second arg, `clientId`, and forward to the output selector
        (state, portfolioId) => portfolioId
    ],
    // Output selector gets (`items, clientid)` as args
    (items, portfolioId) => getPortfolioDetail(items, portfolioId)
);
