import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Card,
  Stack,
  Switch,
  TextField,
  FormControlLabel,
  Typography,
  FormHelperText,
  FormLabel,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  OutlinedInput,
  Autocomplete,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import countries from 'src/utils/Countries';
import UploadAvatar from '../../../components/upload/UploadAvatar';
import { fData } from 'src/utils/formatNumber';
import MuiPhoneNumber from 'mui-phone-number';
import 'yup-phone';
import useGetClientList from 'src/hooks/useGetClientList';
import { Theme, useTheme } from '@mui/material/styles';
import { ALPHA_NUMERIC_REGEX, ALPHA_REGEX } from '../../../constants/AppConstants';
import { isEmpty } from 'lodash';

const roles = [
  { name: 'Admin', value: 'admin' },
  { name: 'Client', value: 'client' },
  { name: 'Maker Admin', value: 'makeradmin' },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

const AddUser = ({ data, onSubmitData, isLoading }) => {
  const UpdateUserSchema = Yup.object().shape({
    displayName: Yup.string().required('Please enter valid Name'),
    email: Yup.string().required('Please enter Email').email('Please enter valid Email'),
    role: Yup.string().required('Please select valid Role'),
    client: Yup.string().when('role', {
      is: 'client',
      then: Yup.string().required('Please select valid client'),
    }),
  });

  const { dropDownData } = useGetClientList();

  const [isShowClientInput, setShowClientInput] = useState(false);

  const isDisabled = data?.onlyview || false;

  const theme = useTheme();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      displayName: data?.displayName || '',
      email: data?.email || '',
      phone: data?.phone || '',
      status: data?.status || 'active',
      role: data?.role || '',
      client: data?.client || '',
    },

    validationSchema: UpdateUserSchema,
    onSubmit: (values, { setErrors, setSubmitting }) => {
      console.log('Data', values);
      onSubmitData(values);
    },
  });

  const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  useEffect(() => {
    if (!isEmpty(data)) {
      if (data.role === 'client') {
        setShowClientInput(true);
      }
    }
  }, [data]);

  const onChangePhonenumber = useCallback(
    (value) => {
      setFieldValue('phone', value);
    },
    [setFieldValue]
  );

  const onSelectClientDropDown = useCallback(
    (e, val) => {
      // console.log('ClientId', e.target.value);
      setFieldValue('client', val.id);
    },
    [setFieldValue]
  );

  const onSelectRoletDropDown = useCallback(
    (e) => {
      setFieldValue('role', e.target.value);
      setShowClientInput(e.target.value === 'client');
    },
    [setFieldValue]
  );

  const handleChange = (event) => {
    const value = event.target.checked ? 'active' : 'inactive';
    setFieldValue('status', value);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={{ xs: 2, md: 3 }}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    disabled={isDisabled}
                    label="Name"
                    {...getFieldProps('displayName')}
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyDown={(event) => {
                      if (!ALPHA_NUMERIC_REGEX.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    error={Boolean(touched.displayName && errors.displayName)}
                    helperText={touched.displayName && errors.displayName}
                  />
                  <TextField
                    fullWidth
                    disabled={isDisabled}
                    label="Email Address"
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <MuiPhoneNumber
                    fullWidth
                    disabled={isDisabled}
                    defaultCountry={'us'}
                    variant="outlined"
                    {...getFieldProps('phone')}
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={touched.phone && errors.phone}
                    onChange={onChangePhonenumber}
                  />
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-single-role-label">Role</InputLabel>
                    <Select
                      labelId="demo-single-role-label"
                      id="demo-single-role"
                      fullWidth
                      disabled={isDisabled}
                      value={values.role}
                      onChange={onSelectRoletDropDown}
                      input={<OutlinedInput id="demo-single-role" label="role" />}
                      error={Boolean(touched.role && errors.role)}
                      MenuProps={MenuProps}
                    >
                      {roles.map((item) => (
                        <MenuItem key={item.value} value={item.value} style={getStyles(item.name, item.name, theme)}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText sx={{ color: 'red' }}>{touched.role && errors.role}</FormHelperText>
                  </FormControl>
                </Stack>
                {isShowClientInput && (
                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                    <FormControl sx={{ width: '100%' }}>
                      {/* <InputLabel id="demo-single-client-label">Client</InputLabel> */}
                      <Autocomplete
                        labelId="demo-single-client-label"
                        id="demo-single-client"
                        fullWidth
                        disabled={isDisabled}
                        value={dropDownData.find((item) => item.id === values.client)}
                        options={dropDownData}
                        getOptionLabel={(option) => option.name}
                        onChange={onSelectClientDropDown}
                        renderInput={(params) => <TextField {...params} />}
                        error={Boolean(touched.client && errors.client)}
                        MenuProps={MenuProps}
                      />
                      {/* {dropDownData.map((item) => (
                          <MenuItem key={item.id} value={item.id} style={getStyles(item.name, item.name, theme)}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select> */}
                      <FormHelperText sx={{ color: 'red' }}>{touched.client && errors.client}</FormHelperText>
                    </FormControl>
                  </Stack>
                )}
                <FormLabel component="legend">Status</FormLabel>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography>In-Active</Typography>
                  <Switch
                    {...getFieldProps('status')}
                    disabled={isDisabled}
                    checked={values.status === 'active'}
                    color="primary"
                    onChange={handleChange}
                  />
                  <Typography>Active</Typography>
                </Stack>
              </Stack>
              {!isDisabled && (
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <LoadingButton type="submit" variant="contained" loading={isLoading}>
                    Save Changes
                  </LoadingButton>
                </Box>
              )}
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

AddUser.propTypes = {
  data: PropTypes.object,
  onSubmitData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default AddUser;
