import { createSelector } from 'reselect'
import isEmpty from 'lodash/isEmpty'

const getUserData = state => state?.user?.user_list

export const getUsertList = createSelector(
    [getUserData],
    (list) => list
)

// Get User By Id
function getUserDetail(arr, userId) {
    const user = arr.find((item) => item.ID == userId)
    return user
}

export const getUserById = createSelector(
    [
        // Usual first input - extract value from `state`
        state => getUserData(state),
        // Take the second arg, `clientId`, and forward to the output selector
        (state, userId) => userId
    ],
    // Output selector gets (`items, clientid)` as args
    (items, userId) => getUserDetail(items, userId)
);
