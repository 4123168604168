import ApiConstants from './ApiConstants';
import axios from 'axios';
import store from '../store/Store'
import isNil from 'lodash/isNil'
import { storeLoginInfo, logout } from '../slices/Login.Slice';
import { requestLogout } from '../actions/Login.Action'

export const api = axios.create({
    baseURL: ApiConstants.BASE_URL
});

export function setToken(token) {
    api.defaults.headers.Authorization = token;
}

export function clearToken() {
    delete api.defaults.headers["Authorization"]
}

export function getToken() {
    return api.defaults.headers["Authorization"]
}

function baseAxios(options) {

    api.defaults.headers.common["Content-Type"] = 'application/json'
    // api.defaults.headers.common["Access-Control-Allow-Headers"] = '*'
    if (options.headers && options.headers['Content-Type']) {
        api.defaults.headers.common["Content-Type"] = options.headers['Content-Type']
    }
    if (options.headers && options.headers['Authorization']) {
        api.defaults.headers.common["Authorization"] = options.headers['Authorization']
    }

    //api.defaults.headers =  options.headers == undefined ? defaultHeaders : options.headers
    api.defaults.timeout = options.timeout || 30000

    return api
}


api.interceptors.request.use(req => {
    const urlString = req.url
    console.log(`${req.method} ${req.url}`);
    // Important: request interceptors **must** return the request.
    return req;
});

api.interceptors.response.use(response => responseHandler(response), errorResponseHandler);

function responseHandler(response) {
    return Promise.resolve(response);
}

function errorResponseHandler(error) {
    // check for errorHandle config
    console.log(error);

    return refreshToken(error);
}

async function refreshToken(error) {
    // const dispatch = useDispatch()
    const {
        config,
        response,
    } = error;
    let originalRequest = config;
    if (response && response.status && response.data) {
        const { status, data } = response
        console.log('Status.....', status)
        console.log('Error....', data)
        if ((status === 500 && data && data?.message == "jwt expired")) {
            const state = store.getState()
            if (!isNil(state) && !isNil(state.auth)) {
                const { refreshToken } = state.auth
                const authInfo = state.auth
                const payload = { refreshToken: refreshToken }
                const response = await executeRequest('post', ApiConstants.REFRESH_TOKEN, payload);
                const access_token = response.data?.accessToken || ""
                setToken(access_token)
                const responseData = { ...authInfo, access_token: access_token }
                store.dispatch(storeLoginInfo(responseData))
                originalRequest.headers['Authorization'] = access_token
                return api(originalRequest);
            }
        }
        else if ((status === 501 && data && data?.message == "Refresh Token not Valid.")) {
            store.dispatch(requestLogout())
        }
    }
    return Promise.reject(error);
}

function executeRequest(method, pathname, data, options = {}) {
    const body = method === 'get' || !data ? {} : { data }
    const reqObj = { method, url: pathname, params: options.query, ...body }

    const baseAxiosRequest = baseAxios(options)
    return new Promise(async (resolve, reject) => {
        try {
            const res = await baseAxiosRequest
                .request(reqObj);
            resolve(res);
        }
        catch (error) {
            
            reject(error);
        }
    })
}




export default {
    get(pathname, options) {
        return executeRequest('get', pathname, null, options)
    },

    post(pathname, data, options) {
        return executeRequest('post', pathname, data, options)
    },

    put(pathname, data, options) {
        return executeRequest('put', pathname, data, options)
    },

    delete(pathname, data, options) {
        return executeRequest('delete', pathname, data, options)
    },

    all(promises) {
        return axios.all(promises)
    }
}