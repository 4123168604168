import * as Yup from 'yup';
import { useCallback, useState, useEffect } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { Theme, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Card,
  Stack,
  OutlinedInput,
  TextField,
  MenuItem,
  InputLabel,
  FormControl,
  Switch,
  Typography,
  FormHelperText,
  FormLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import isEmpty from 'lodash/isEmpty';
import useGetClientList from '../../../hooks/useGetClientList';
import countries from 'src/utils/Countries';
import { ALPHA_NUMERIC_REGEX, NUM_REGEX } from '../../../constants/AppConstants';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

const sectorList = [
  { name: '1', value: 'Communication Services' },
  { name: '2', value: 'Consumer Discretionary' },
  { name: '3', value: 'Consumer Staples' },
  { name: '4', value: 'Energy' },
  { name: '5', value: 'Financials' },
  { name: '6', value: 'Health care' },
  { name: '7', value: 'Industrials' },
  { name: '8', value: 'Information Technology' },
  { name: '9', value: 'Materials' },
  { name: '10', value: 'Real Estate' },
  { name: '11', value: 'Utilities' },
];

const CompanyForm = ({ data, onSubmitData, isLoading }) => {
  const theme = useTheme();
  const UpdateCompanySchema = Yup.object().shape({
    name: Yup.string().required('Please enter valid Name'),
    code: Yup.string().required('Please enter valid Code'),
    country: Yup.string().required('Please select valid Country'),
    sector: Yup.string().required('Please select valid Sector'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.name || '',
      code: data?.code || '',
      country: data?.country || '',
      sector: data?.sector || '',
      status: data?.status || 'active',
    },

    validationSchema: UpdateCompanySchema,
    onSubmit: (values, { setErrors, setSubmitting }) => {
      console.log('Data', values);
      onSubmitData(values);
    },
  });

  const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  const isView = data?.onlyview || false;

  const isDiabled = !isEmpty(data) || isView;

  const onSelecDropDown = useCallback(
    (e) => {
      const value = e.target.value;
      setFieldValue('sector', value);
    },
    [setFieldValue]
  );

  const handleChange = (event) => {
    const value = event.target.checked ? 'active' : 'inactive';
    setFieldValue('status', value);
  };

  const onSelectCountryDropDown = useCallback(
    (e) => {
      const value = e.target.value;
      setFieldValue('country', value);
    },
    [setFieldValue]
  );

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={{ xs: 2, md: 3 }}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    disabled={isView}
                    label="Name"
                    {...getFieldProps('name')}
                    inputProps={{
                      maxLength: 50,
                    }}
                    // onKeyDown={(event) => {
                    //   if (!ALPHA_NUMERIC_REGEX.test(event.key)) {
                    //     event.preventDefault();
                    //   }
                    // }}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                  <TextField
                    fullWidth
                    label="Code"
                    disabled={isDiabled}
                    inputProps={{
                      maxLength: 20,
                    }}
                    // onKeyDown={(event) => {
                    //   if (!NUM_REGEX.test(event.key) && event.key !== 'Backspace') {
                    //     event.preventDefault();
                    //   }
                    // }}
                    {...getFieldProps('code')}
                    error={Boolean(touched.code && errors.code)}
                    helperText={touched.code && errors.code}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-country-label">Country</InputLabel>
                    <Select
                      labelId="demo-country-label"
                      id="demo-country-chip"
                      fullWidth
                      data-testid="country"
                      disabled={isView}
                      value={values.country}
                      onChange={onSelectCountryDropDown}
                      input={<OutlinedInput id="select-country" label="Country" />}
                      error={Boolean(touched.country && errors.country)}
                      MenuProps={MenuProps}
                    >
                      {countries.map((item) => (
                        <MenuItem key={item.code} value={item.label} style={getStyles(item.label, item.label, theme)}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText sx={{ color: 'red' }}>{touched.country && errors.country}</FormHelperText>
                  </FormControl>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-multiple-chip-label">Sector</InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      disabled={isDiabled}
                      fullWidth
                      value={values.sector}
                      onChange={onSelecDropDown}
                      input={<OutlinedInput id="select-multiple-chip" label="Sector" />}
                      error={Boolean(touched.sector && errors.sector)}
                      MenuProps={MenuProps}
                    >
                      {sectorList.map((item) => (
                        <MenuItem key={item.name} value={item.value} style={getStyles(item.name, item.name, theme)}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText sx={{ color: 'red' }}>{touched.sector && errors.sector}</FormHelperText>
                  </FormControl>
                </Stack>
                <FormLabel component="legend" aria-label='Status'>Status</FormLabel>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography>In-Active</Typography>
                  <Switch
                    aria-label='Status'
                    {...getFieldProps('status')}
                    disabled={isView}
                    checked={values.status === 'active'}
                    color="primary"
                    onChange={handleChange}
                  />
                  <Typography>Active</Typography>
                </Stack>
              </Stack>
              {!isView && (
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                  <LoadingButton type="submit" variant="contained" loading={isLoading}>
                    Save Changes
                  </LoadingButton>
                </Box>
              )}
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

CompanyForm.propTypes = {
  data: PropTypes.object,
  onSubmitData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default CompanyForm;
