import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  requestGetCompanyList,
  requestDeleteCompany,
  requestUpdateCompany,
  requestUpdateCompanyStatus,
  requestCreateCompany,
  requestCompanyWiseClientInfo,
  requestCompanyClientStatus,
  requestGetCompnayFilterRecords,
  requestCompanyBulkUploadQuestions,
  requestGetSupportingFileList,
  requestUploadSupportingFile
} from '../actions/Company.Action';
import { getCompanyList, getCompanyWiseClientList, getCompanyDropDownList } from 'src/selectors/Company.Selector';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from '../routes/path';
import { requestBulkUpload } from '../actions/Common.Action';

const useGetCompanyInfo = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingCompanyWiseClientList, setIsFetchingCompanyWiseClientList] = useState(false);
  const [isDeletingCompany, setIsDeleteCompany] = useState(false);
  const [isUpdatingCompanyStatus, setIsUpdateCompanyStatus] = useState({ status: false, companyId: null });
  const [isUpdatingCompanyInfo, setIsUpdateCompanyInfo] = useState({ status: false, companyId: null });
  const [isUpdatingCompanyClientFileInfo, setIsUpdateCompanyCompanyClientFileInfo] = useState({
    status: false,
    id: null,
  });
  const [isCreatingCompanyInfo, setIsCreatingCompanyInfo] = useState(false);
  const [isBulkUploadingCompany, setIsBulkUploadingCompany] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [companyFilter, setCompanyFilter] = useState([]);
  const [supportingFileList, setSupportingFileList] = useState([])
  const [isFetchingSupportingFileList, setIsFetchingSupportingFileList] = useState(false)
  const [isUploadingSupportingFile, setIsUploadingSupportingFile] = useState(false)

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { data, arrCompanyWiseClientList, dropDownCompanyData } = useSelector((state) => ({
    data: getCompanyList(state),
    arrCompanyWiseClientList: getCompanyWiseClientList(state),
    dropDownCompanyData: getCompanyDropDownList(state),
  }));

  useEffect(() => {
    loadCompanyList();
  }, []);

  useEffect(() => {
    if (companyFilter.status?.length || companyFilter.country?.length || companyFilter.industry?.length) {
      loadCompanyWithFilters(companyFilter);
    } 
  }, [companyFilter]);

  const loadCompanyList = () => {
    setIsFetching(true);
    dispatch(requestGetCompanyList(onGetCompanyListSuccess, onCompanyListError));
  };

  const reloadData = () => {
    loadCompanyList();
  };

  const loadCompanyWithFilters = (filter) => {
    setIsFetching(true);
    if (!filter.status?.length) {
      filter.status = null
    }
    if (!filter.industry?.length) {
      filter.industry = null
    }
    if (!filter.country?.length) {
      filter.country = null
    } 
    
    dispatch(requestGetCompnayFilterRecords(filter, onGetCompanyilterSuccess, onCompanyFilterError))
  }

  const onGetCompanyilterSuccess = (data) => {
    setFilterData(data.data?.data);
    setIsFetching(false);
  };

  const onCompanyFilterError = (error) => {
    setIsFetching(false);
  };

  const requestCompanyClientWiseData = (companyCode) => {
    setIsFetchingCompanyWiseClientList(true);
    dispatch(
      requestCompanyWiseClientInfo(companyCode, onGetCompanyClientWiseDataSuccess, onGetCompanyClientWiseDataError)
    );
  };

  const onGetCompanyClientWiseDataSuccess = (data) => {
    setIsFetchingCompanyWiseClientList(false);
  };

  const onGetCompanyClientWiseDataError = (error) => {
    setIsFetchingCompanyWiseClientList(false);
  };

  const onGetCompanyListSuccess = (data) => {
    setIsFetching(false);
  };

  const onCompanyListError = (error) => {
    setIsFetching(false);
  };

  const deleteCompany = (companyId) => {
    setIsDeleteCompany(true);
    dispatch(requestDeleteCompany(companyId, onDeleteCompanySuccess, onDeleteCompanyError));
  };

  const onDeleteCompanySuccess = (data) => {
    setIsDeleteCompany(false);
    enqueueSnackbar('Company deleted successfully', {
      variant: 'success',
    });
  };

  const onDeleteCompanyError = (error) => {
    setIsDeleteCompany(false);
    const message = error?.response?.data?.message || error?.message || 'Company deletion failed. Please try again later.';
    enqueueSnackbar(message, {
      variant: 'error',
    });
  };

  const requestCreateCompanyInfo = (payload) => {
    setIsCreatingCompanyInfo(true);
    dispatch(requestCreateCompany(payload, onCreateCompanyInfoSuccess, onCreateCompanyInfoError));
  };

  const onCreateCompanyInfoSuccess = (data) => {
    setIsCreatingCompanyInfo(false);
    enqueueSnackbar('Company created successfully', {
      variant: 'success',
    });
    navigate(PATH_DASHBOARD.general.company_list, { replace: true });
  };

  const onCreateCompanyInfoError = (error) => {
    setIsCreatingCompanyInfo(false);
    const message = error?.response?.data?.message || error?.message || 'Please try again.';
    enqueueSnackbar(message, {
      variant: 'error',
    });
  };

  const requestEditCompany = (companyId, payload) => {
    setIsUpdateCompanyInfo({ status: true, companyId: companyId });
    dispatch(requestUpdateCompany(companyId, payload, onUpdateCompanyInfoSuccess, onUpdateCompanyInfoError));
  };

  const onUpdateCompanyInfoSuccess = (data) => {
    setIsUpdateCompanyInfo({ status: false, companyId: null });
    enqueueSnackbar('Company info updated successfully', {
      variant: 'success',
    });
    navigate(PATH_DASHBOARD.general.company_list, { replace: true });
  };

  const onUpdateCompanyInfoError = (error) => {
    setIsUpdateCompanyInfo({ status: false, companyId: null });
    const message = error?.response?.data?.message || error?.message || 'Please try again.';
    enqueueSnackbar(message, {
      variant: 'error',
    });
  };

  // Update Company Status
  const requestUpdateCompanyApprovalStatus = (companyId, payload) => {
    setIsUpdateCompanyStatus({ status: true, companyId: companyId });
    dispatch(requestUpdateCompanyStatus(companyId, payload, onUpdateCompanyStatusSuccess, onUpdateCompanyStatusError));
  };

  const onUpdateCompanyStatusSuccess = (data) => {
    setIsUpdateCompanyStatus({ status: false, companyId: null });
    reloadData()
    enqueueSnackbar('Company status updated successfully', {
      variant: 'success',
    });
  };

  const onUpdateCompanyStatusError = (error) => {
    setIsUpdateCompanyStatus({ status: false, companyId: null });
    const message = error?.response?.data?.message || error?.message || 'Company status updated failed';
    enqueueSnackbar(message, {
      variant: 'error',
    });
  };

  //Update Company wise Client File Status
  const requestUpdateCompanyClientApprovalStatusInfo = (id, payload) => {
    setIsUpdateCompanyCompanyClientFileInfo({ status: true, id: id });
    dispatch(requestCompanyClientStatus(payload, onUpdateCompanyClientStatusSuccess, onUpdateCompanyClientStatusError));
  };

  const onUpdateCompanyClientStatusSuccess = (data) => {
    setIsUpdateCsetIsUpdateCompanyCompanyClientFileInfoompanyStatus({ status: false, id: null });
    enqueueSnackbar('Company client status updated successfully', {
      variant: 'success',
    });
  };

  const onUpdateCompanyClientStatusError = (error) => {
    setIsUpdateCompanyCompanyClientFileInfo({ status: false, id: null });
    const message = error?.response?.data?.message || error?.message || 'Company client status updated failed';
    enqueueSnackbar(message, {
      variant: 'error',
    });
  };

  // Bulk Upload
  const requestCreateCompanyInBulk = (payload) => {
    setIsBulkUploadingCompany(true);
    dispatch(requestBulkUpload(payload, onBulkUploadSuccess, onBulkUploadError));
  };

  const onBulkUploadSuccess = (data) => {
    setIsBulkUploadingCompany(false);
    enqueueSnackbar('File Uploaded successfully. Creating Company in Bulk...Please wait', {
      variant: 'success',
    });
  };

  const onBulkUploadError = (error) => {
    const message = error?.response?.data?.message || error?.message || 'File upload failed';
    setIsBulkUploadingCompany(false);
    enqueueSnackbar(message, {
      variant: 'error',
    });
  };

  const requestGetSupportingfileList = (companyCode, clientId) => {
    setIsFetchingCompanyWiseClientList(true);
    dispatch(
      requestGetSupportingFileList(companyCode, clientId, onGetSupportingFileListWithSuccess, onGetSupportingFilelistWithError)
    );
  };

  const onGetSupportingFileListWithSuccess = (data) => {
    setIsFetchingSupportingFileList(false);
    setSupportingFileList(data)
  };

  const onGetSupportingFilelistWithError = (error) => {
    setIsFetchingSupportingFileList(false);
  };


  //Upload Supporting File list
  const requestUploadSupportingFileInfo = (payload) => {
    setIsUploadingSupportingFile(true);
    dispatch(requestUploadSupportingFile(payload, onUploadSupportingFileSuccess, onUploadSupportingFileError));
  };

  const onUploadSupportingFileSuccess = (data) => {
    setIsUploadingSupportingFile(false);
    enqueueSnackbar('File Uploaded successfully', {
      variant: 'success',
    });
  };

  const onUploadSupportingFileError = (error) => {
    const message = error?.response?.data?.message || error?.message || 'File upload failed';
    setIsUploadingSupportingFile(false);
    enqueueSnackbar(message, {
      variant: 'error',
    });
  };

  return {
    data,
    isFetching,
    reloadData,
    dropDownCompanyData,
    isDeletingCompany,
    deleteCompany,
    isUpdatingCompanyStatus,
    requestUpdateCompanyApprovalStatus,
    isUpdatingCompanyInfo,
    requestEditCompany,
    isCreatingCompanyInfo,
    requestCreateCompanyInfo,
    isFetchingCompanyWiseClientList,
    arrCompanyWiseClientList,
    requestCompanyClientWiseData,
    isUpdatingCompanyClientFileInfo,
    requestUpdateCompanyClientApprovalStatusInfo,
    isBulkUploadingCompany,
    requestCreateCompanyInBulk,
    filterData,
    setCompanyFilter,
    isFetchingSupportingFileList,
    supportingFileList,
    requestGetSupportingfileList,
    isUploadingSupportingFile,
    requestUploadSupportingFileInfo
  };
};

export default useGetCompanyInfo;
