import * as types from '../constants/ActionTypes';
import { put, takeLatest, call } from 'redux-saga/effects';
import ApiConstants from '../api/ApiConstants';
import { setToken, clearToken } from '../api';
import * as LoginActions from '../actions/Login.Action';
import API from '../api';
import { storeLoginInfo } from '../slices/Login.Slice';

function* login(action) {
  const { email, password, onSuccess, onError } = action;
  try {
    const body = { email: email, password: password };
    const response = yield call(API.post, ApiConstants.LOGIN, body);
    const data = response.data;
    // const { user, accessToken } = data
    // setToken(accessToken)
    // yield put(storeLoginInfo({ user: user, access_token: accessToken }))
    onSuccess(data);
  } catch (error) {
    onError(error);
  }
}

function* requestForgotPassword(action) {
  const { email, onSuccess, onError } = action;
  try {
    const body = { email: email };
    const response = yield call(API.post, ApiConstants.FORGOT_PASSWORD, body);
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestGeneratePassword(action) {
  const { email, code, password, onSuccess, onError } = action;
  try {
    const body = { email: email, code: code, password: password };
    const response = yield call(API.put, ApiConstants.GENERATE_PASSWORD, body);
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestUpdatePassword(action) {
  const { payload, token, onSuccess, onError } = action;
  try {
    const headers = { "headers" : { Authorization: token }}
    const response = yield call(API.put, ApiConstants.UPDATE_PASSWORD, payload, headers);
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestGetMyAccountInfo(action) {
  const { onSuccess, onError } = action;
  try {
    const response = yield call(API.get, ApiConstants.MY_ACCOUNT,);
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestGetDashBoardInfo(action) {
  const { onSuccess, onError } = action;
  try {
    const response = yield call(API.get, ApiConstants.DASHBOARD_API,);
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

export function* watchLogin() {
  yield takeLatest(types.LOGIN_REQUEST_INITIATED, login),
    yield takeLatest(types.FORGOT_PASSWORD_REQUEST, requestForgotPassword),
    yield takeLatest(types.GENERATE_PASSWORD_REQUEST, requestGeneratePassword),
    yield takeLatest(types.UPDATE_PASSWORD_REQUEST, requestUpdatePassword),
    yield takeLatest(types.MY_ACCOUNT_REQUEST, requestGetMyAccountInfo),
    yield takeLatest(types.DASHBOARD_REQUEST, requestGetDashBoardInfo);
}
