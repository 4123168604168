import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo } from 'src/selectors/Login.Selector';
import { requestLogout } from 'src/actions/Login.Action';

const useAuth = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => ({
    user: getUserInfo(state),
  }));

  const logout = () => {
    dispatch(requestLogout());
  };

  const storeUserInfo = (key, data) => {
    window.localStorage.setItem(key, JSON.stringify(data));
  };

  const getUserInfoFromVault = (key) => {
    const info = window.localStorage.getItem(key);
    return JSON.parse(info);
  };

  const clearuserInfo = (key) => {
    window.localStorage.removeItem(key);
  };

  return {
    user,
    logout,
    storeUserInfo,
    getUserInfoFromVault,
    clearuserInfo,
  };
};

export default useAuth;
