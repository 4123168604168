import { createSlice } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'

const clientSlice = createSlice({
    name:'client',
    initialState: {
        client_list: [],
        default_client_list: [],
    },
    reducers: {
        storeClientData:(state, action) => {
            const { data } = action.payload
            state.client_list = data
        },
        storeDefaultClientData:(state, action) => {
            const { data } = action.payload
            state.default_client_list = data
        },
        deleteClientInfo: (state, action) => {
            const { clientId }  = action.payload
            state.client_list = state.client_list.filter((item) => item.ID !== clientId)
        },
        changeClientApprovalStatus: (state, action) => {
            const { clientId, approvalstatus }  = action.payload
            state.client_list = state.client_list.map((item, index) => {
                if (item.ID === clientId) {
                    item["approvalstatus"] = approvalstatus
                }
                return item
            })
        }
    }
})

export const { storeClientData, deleteClientInfo, changeClientApprovalStatus, storeDefaultClientData } = clientSlice.actions

export default clientSlice.reducer