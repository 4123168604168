import React, { useState } from 'react'
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
// material
import { Box, Grid, Card, Stack, Switch, TextField, FormControlLabel, Typography, FormHelperText, Container } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PortfolioForm from 'src/sections/@dashboard/portfolio/PortfolioForm';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'src/routes/path';
import { useDispatch } from 'react-redux'
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { requestEditPortfolio } from 'src/actions/Portfolio.Action';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getPortfolioById } from '../../selectors/Portfolio.Selector';

const EditPortfolio = () => {
    const isMountedRef = useIsMountedRef();
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setSubmitting] = useState(false)
    const navigate = useNavigate()

    const { portfolioId } = useParams()

    const { data } = useSelector((state) => ({
        data: getPortfolioById(state, portfolioId)
    }))

    console.log(data)

    const onSubmitData = (data) => {
        setSubmitting(true)
        dispatch(requestEditPortfolio(portfolioId, data, onSuccessEditPortfolio, onErrorEditPortfolio))
    }

    const onSuccessEditPortfolio = (data) => {
        setSubmitting(false)
        enqueueSnackbar('Portfolio updated successfully', {
            variant: 'success'
        });
        navigate(PATH_DASHBOARD.general.portfolio_list, { replace: true })
    }

    const onErrorEditPortfolio = (error) => {
        setSubmitting(false)
        const message = error?.response?.data?.message || error?.message || "Please try again."
        enqueueSnackbar(message, {
            variant: 'error'
        });
    }

    return (
        <Container>
            <HeaderBreadcrumbs
                heading="Edit Portfolio"
                links={[
                    { name: 'Home', href: PATH_DASHBOARD.root },
                    { name: 'Portfolio List', href: PATH_DASHBOARD.general.portfolio_list },
                    { name: 'Edit Portfolio' }
                ]}
            />
            <PortfolioForm onSubmitData={onSubmitData} isLoading={isSubmitting} data={data}/>
        </Container>
    )
}

export default EditPortfolio