import { ENV } from '../constants/AppConstants'

const BASE_URL = ENV.dev

const ApiConstants = {
    BASE_URL: BASE_URL,
    LOGIN: "/login",
    FORGOT_PASSWORD: "/forgotpassword",
    GENERATE_PASSWORD: "/regenreatePassword",
    LIST_CLIENT: "/listclient",
    CREATE_CLIENT: "/client",
    LIST_USER: "/listuser",
    ADD_USER: "/adduser",
    EDIT_CLIENT:(clientId) => `/client/${clientId}`,
    EDIT_USER:(userId) => `/user/${userId}`,
    DELETE_CLIENT:(clientId) => `/account/${clientId}`,
    STATUS_UPDATE_CLIENT:(clientId) => `/statusupdate/${clientId}`,
    DELETE_USER:(userId) => `/statusupdateuser/${userId}`,
    STATUS_UPDATE_USER:(userId) => `/statusupdateuser/${userId}`,
    LIST_NEWS: `/list-news`,
    ADD_NEWS: "/news",
    EDIT_NEWS:(newsId) => `/editnews/${newsId}`,
    DELETE_NEWS:(newsId) => `/delete-news/${newsId}`,
    NEWS_STATUS_CHANGE: (newsId) => `statuschange/${newsId}`,
    NEWS_FILTER: "/newsFilter",
    LIST_PORTFOLIO: "/listportfolio",
    ADD_PORTFOLIO: "/portfolio",
    PORTFOLIO_ACTIONS:(portfolioId) =>  `/portfolio/${portfolioId}`,
    STATUS_UPDATE_PORTFOLIO:(portfolioId) => `/statusupdateportfolio/${portfolioId}`,
    LIST_TEMPLATES: "/templates",
    ADD_TEMPLATE: "/template",
    STATUS_UPDATE_TEMPLATE:(templateId) =>  `/statusupdatetemplates/${templateId}`,
    VIEW_TEMPLATE:(templateId) => `/template/${templateId}`,
    UPLOAD_TEMPLATE:(templateId) => `/templatefileupload/${templateId}`,
    DELETE_TEMPLATE:(templateId) => `/template/${templateId}`,
    COMPANY_LIST: '/company/list',
    CREATE_COMPANY: '/company',
    UPDATE_COMPANY:(companyId) => `/company/${companyId}`,
    STATUS_UPDATE_COMPANY:(companyId) => `/statusupdatecompany/${companyId}`,
    DELETE_COMPANY:(companyId) => `/deletecompany/${companyId}`,
    TEMPLATE_FILE_UPLOAD:(templateId) => `/templatefileupload/${templateId}`,
    COMPANY_WISE_CLIENT:(companyCode) => `/companyclient/${companyCode}`,
    COMPANY_CLIENT_FILE_STATUS: `/bulkuploadQuestion`,
    BULK_UPLOAD: "/bulkupload",
    BULK_UPLOAD_QUESTION: '/bulkuploadQuestion',
    PUBLIC_UPLOADED: '/publicuploaded',
    FILTER_DATA_REQUEST: "/filter",
    COMPANY_FILTER: "/companyFilter",
    USER_FILTER: '/filteruser',
    VIEW_TEMPLATE:(templateId) => `/template/${templateId}`,
    VIEW_PORTFOLIO:(portfolioId) => `/portfolio/${portfolioId}`,
    UPDATE_PASSWORD: "/updatepassword",
    UPLOAD_SUPPORTING_DATA_FILES:  `/datafiles`,
    SUPPORTING_DATA_FILES: (companyCode, clientId) => `/datafiles/${companyCode}/${clientId}`,
    REFRESH_TOKEN: '/refreshToken',
    ARCHIVE_PORTFOLIO: '/archiveportfolio',
    MY_ACCOUNT: '/myAccount',
    UPDATE_TEMPLATE_ACTIVE_STATUS: (templateId) => `/statusupdatetemplates/${templateId}`,
    ACTIVE_COMPANY_LIST: '/getactiveCompanyList',
    DASHBOARD_API: '/dashboard',
    MATERIALITY_METRICS: '/materiality',
    LIST_DATA_POINTS: '/listdatapoints',
    LIST_RULES: '/listrules',
    LIST_DEFAULT_CLIENT: '/listclientdefault'  
}

export default ApiConstants