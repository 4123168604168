// component
import SvgColor from '../../../components/svg-color';
import { PATH_DASHBOARD } from 'src/routes/path';
import PeopleIcon from '@mui/icons-material/People';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PublicIcon from '@mui/icons-material/Public';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import SchemaIcon from '@mui/icons-material/Schema';
import GroupsIcon from '@mui/icons-material/Groups';
// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Home',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'My Universe',
    icon: <PublicIcon sx={{ width: 22, height: 22 }} htmlColor="gray"/>,
    children: [
      { title: 'companies', path: PATH_DASHBOARD.general.company_list, icon: icon('ic_blog') },
      { title: 'Company News Management', path: PATH_DASHBOARD.general.company_news, icon: <NewspaperIcon sx={{ width: 22, height: 22 }} htmlColor="gray"/> }
    ]
  },
  {
    title: 'Portfolio Management',
    path: '/dashboard/portfolio',
    icon: <BusinessCenterIcon sx={{ width: 22, height: 22 }} htmlColor="gray"/>,
  },
  {
    title: 'User Management',
    path: '/dashboard/user',
    icon: <PeopleIcon sx={{ width: 22, height: 22 }} htmlColor="gray"/>,
  },
  {
    title: 'Client Management',
    path: PATH_DASHBOARD.client.root,
    icon: <Diversity2Icon sx={{ width: 20, height: 20 }} htmlColor="gray"/>,
  },
  {
    title: 'ESG Framework',
    icon: <SchemaIcon sx={{ width: 22, height: 22 }} htmlColor="gray"/>,
    children: [
      { title: 'Materiality Metrics', path: PATH_DASHBOARD.general.materiality_metrics, icon: <BubbleChartIcon sx={{ width: 22, height: 22 }} htmlColor="gray"/> },
      { title: 'Datapoints', path: PATH_DASHBOARD.general.dataPoints, icon: <NewspaperIcon sx={{ width: 22, height: 22 }} htmlColor="gray"/> }
    ]
  },
  {
    title: 'Corporate Framework',
    icon: <GroupsIcon sx={{ width: 22, height: 22 }} htmlColor="gray"/>,
    children: [
      { title: 'ESG Weightage', path: PATH_DASHBOARD.general.template_list, icon: <BubbleChartIcon sx={{ width: 22, height: 22 }} htmlColor="gray"/> },
      { title: 'ESG Rules', path: PATH_DASHBOARD.general.esgrules, icon: <NewspaperIcon sx={{ width: 22, height: 22 }} htmlColor="gray"/> }
    ]
  }
  // {
  //   title: 'Template Management',
  //   path: '/dashboard/template',
  //   icon:  <ViewQuiltIcon sx={{ width: 22, height: 22 }} htmlColor="gray"/>,
  // }
];

export default navConfig;
