import { combineReducers } from "@reduxjs/toolkit";
import authReducer from './Login.Slice'
import clientReducer from './Client.Slice'
import userReducer from './User.Slice'
import portfolioReducer from './Portfolio.Slice'
import templateReducer from './Template.Slice'
import companyReducer from './Company.Slice'
import newsReducer from './News.Slice'
import * as types from '../constants/ActionTypes'


const rootReducer = combineReducers({
  auth: authReducer,
  client: clientReducer,
  user: userReducer,
  portfolio: portfolioReducer,
  template: templateReducer,
  company: companyReducer,
  news: newsReducer
});

const mainRootReducer = (state, action) => {
  if (action.type === types.LOGOUT_REQUEST){
      // clear user data
     // window.localStorage.clear()
      return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

export default mainRootReducer