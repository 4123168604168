import React, { useState } from 'react'
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
// material
import { Box, Grid, Card, Stack, Switch, TextField, FormControlLabel, Typography, FormHelperText, Container } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PortfolioForm from 'src/sections/@dashboard/portfolio/PortfolioForm';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'src/routes/path';
import { useDispatch } from 'react-redux'
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { requestAddPortfolio } from 'src/actions/Portfolio.Action';
import { useNavigate } from 'react-router-dom';


const CreatePortfolio = () => {
    const isMountedRef = useIsMountedRef();
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setSubmitting] = useState(false)
    const navigate = useNavigate()

    const onSubmitData = (data) => {
        setSubmitting(true)
        dispatch(requestAddPortfolio(data, onSuccessAddPortfolio, onErrorAddPortfolio))
    }

    const onSuccessAddPortfolio = (data) => {
        setSubmitting(false)
        enqueueSnackbar('Portfolio created successfully', {
            variant: 'success'
        });
        navigate(PATH_DASHBOARD.general.portfolio_list, { replace: true })
    }

    const onErrorAddPortfolio = (error) => {
        setSubmitting(false)
        const message = error?.response?.data?.message || error?.message || "Please try again."
        enqueueSnackbar(message, {
            variant: 'error'
        });
    }

    return (
        <Container>
            <HeaderBreadcrumbs
                heading="Add Portfolio"
                links={[
                    { name: 'Home', href: PATH_DASHBOARD.root },
                    { name: 'Portfolio List', href: PATH_DASHBOARD.general.portfolio_list },
                    { name: 'Add Portfolio' }
                ]}
            />
            <PortfolioForm onSubmitData={onSubmitData} isLoading={isSubmitting}/>
        </Container>
    )
}

export default CreatePortfolio