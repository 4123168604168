import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import SimpleLayout from '../layouts/simple';
//
import BlogPage from '../pages/BlogPage';
import UserPage from '../pages/UserPage';
import LoadingScreen from '../components/LoadingScreen';
// import LoginPage from '../pages/Auth/LoginPage';
import Page404 from '../pages/Page404';
import ProductsPage from '../pages/ProductsPage';
import DashboardAppPage from '../pages/DashboardAppPage';
import AuthGuard from '../guard/AuthGuard';
import GuestGuard from 'src/guard/GuestGuard';
// import ManageClient from '../pages/Client/ManageClient';
import CreateClient from 'src/pages/Client/CreateClient';
import ForgotPassword from 'src/pages/Auth/ForgotPassword';
import GeneratePassword from 'src/pages/Auth/GeneratePassword';
import CreateUser from 'src/pages/User/CreateUser';
// import ManageUser from 'src/pages/User/ManageUser';
import EditClient from '../pages/Client/EditClient';
import EditUser from '../pages/User/EditUser';
// import ManagePortfolio from '../pages/Portfolio/ManagePortfolio';
import CreatePortfolio from '../pages/Portfolio/CreatePortfolio';
// import ManageTemplate from '../pages/Template/ManageTemplate';
import CreateTemplate from '../pages/Template/CreateTemplate';
import ViewTemplate from '../pages/Template/ViewTemplate';
import EditPortfolio from '../pages/Portfolio/EditPortfolio';
import ManageCompany from '../pages/Company/ManageCompany';
import CreateCompany from '../pages/Company/CreateCompany';
import EditCompany from '../pages/Company/EditCompany';
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

const LoginPage = Loadable(lazy(() => import('../pages/Auth/LoginPage')));
const ManageClient = Loadable(lazy(() => import('../pages/Client/ManageClient')));
const ManageUser = Loadable(lazy(() => import('../pages/User/ManageUser')));
const ManageTemplate = Loadable(lazy(() => import('../pages/Template/ManageTemplate')));
const ManagePortfolio = Loadable(lazy(() => import('../pages/Portfolio/ManagePortfolio')));
const CompanyWiseClientList = Loadable(lazy(() => import('../pages/Company/CompanyWiseClientList')));
const ManageNews = Loadable(lazy(() => import('../pages/Company/ManageNews')));
const CreateNews = Loadable(lazy(() => import('../pages/Company/CreateNews')));
const EditNews = Loadable(lazy(() => import('../pages/Company/EditNews')));
const CreateUserPassword = Loadable(lazy(() => import('../pages/Auth/CreateUserPassword')));
const ViewTemplateScore = Loadable(lazy(() => import('../pages/Template/ViewTemplateScore')));
const ViewPortfolioScore = Loadable(lazy(() => import('../pages/Portfolio/ViewPortfolio')));
const SupportingFileList = Loadable(lazy(() => import('../pages/Company/SupportingFileList')));
const MainDashBoard = Loadable(lazy(() => import('../pages/Dashboard/DashBoard')));
const MLSentimentAnalysis = Loadable(lazy(() => import('../pages/Dashboard/MLSentimentAnalysis')));
const MaterialityMetrics = Loadable(lazy(() => import('../pages/EsgFramework/MaterialityMetrics')));
const DataPoints = Loadable(lazy(() => import('../pages/EsgFramework/DataPoints')));
const EsgRules = Loadable(lazy(() => import('../pages/CorporateFramework/EsgRules')));



export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <MainDashBoard /> },
        {
          path: 'client',
          children: [
            { element: <ManageClient />, index: true },
            { path: 'add', element: <CreateClient /> },
            { path: 'edit-client/:clientId', element: <EditClient /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <ManageUser />, index: true },
            { path: 'add', element: <CreateUser /> },
            { path: 'edit-user/:userId', element: <EditUser /> },
          ],
        },
        {
          path: 'portfolio',
          children: [
            { element: <ManagePortfolio />, index: true },
            { path: 'add', element: <CreatePortfolio /> },
            { path: 'edit/:portfolioId', element: <EditPortfolio /> },
            { path: 'view/:portfolioId', element: <ViewPortfolioScore /> },
          ],
        },
        {
          path: 'template',
          children: [
            { element: <ManageTemplate />, index: true },
            { path: 'add', element: <CreateTemplate /> },
            { path: 'view/:templateId', element: <ViewTemplate /> },
            { path: 'view_score/:templateId', element: <ViewTemplateScore /> },
          ],
        },
        {
          path: 'company',
          children: [
            { element: <ManageCompany />, index: true },
            { path: 'add', element: <CreateCompany /> },
            { path: 'edit/:companyId', element: <EditCompany /> },
            { path: 'clients/:companyCode', element: <CompanyWiseClientList /> },
            { path: 'news', element: <ManageNews /> },
            { path: 'news/add', element: <CreateNews /> },
            { path: 'news/edit/:newsId', element: <CreateNews /> },
            { path: 'supportingfiles/:companyCode/:clientId', element: <SupportingFileList /> },
          ],
        },
        {
          path: 'news',
          children: [
            { element: <ManageNews />, index: true },
            { path: 'add', element: <CreateNews /> },
            { path: 'edit/:newsId', element: <EditNews /> },
          ],
        },
        {
          path: 'esg',
          children: [
            { element: <Navigate to="/dashboard/esg/metrics" />, index: true },
            { path: 'metrics', element: <MaterialityMetrics /> },
            { path: 'dataPoints', element: <DataPoints /> }
          ],
        },
        {
          path: 'corporate',
          children: [
            { element: <Navigate to="/dashboard/corporate/esgrules" />, index: true },
            { path: 'esgrules', element: <EsgRules /> },
            {
              path: 'template',
              children: [
                { element: <ManageTemplate />, index: true },
                { path: 'add', element: <CreateTemplate /> },
                { path: 'view/:templateId', element: <ViewTemplate /> },
                { path: 'view_score/:templateId', element: <ViewTemplateScore /> },
              ],
            },
          ],
        },
        {
          path: 'mlapi',
          children: [
            // { element: <Companies /> },
            { path: 'sentimentAnalysis', element: <MLSentimentAnalysis /> },
          ],
        },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
      ],
    },
    { path: 'generate-password/:email/:code', element: <GeneratePassword /> },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'createUserPassword', element: <CreateUserPassword /> },
      ],
    },
    {
      path: '/',
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '/forgot-password', element: <ForgotPassword /> },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
