import React, { useCallback, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as Yup from 'yup';
import { Stack, Typography, Box, FormHelperText, Link } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import isEmpty from 'lodash/isEmpty'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const INTRUCTIONS = '*Note: Only csv files are allowed';

export default function FileUploadDialog({
  title,
  description,
  onTapCancel,
  onSubmitData,
  instruction = INTRUCTIONS,
  accept = '.csv',
  link = '',
  isMultipleFileUpload = false,
  isSubmittingForm = false,
  ...props
}) {
  const [fileName, setFileName] = useState('');

  const UpdateDataFileSchema = Yup.object().shape({
    file: Yup.mixed().test('required', 'Please select valid file', (file) => {
      // return file && file.size <-- u can use this if you don't want to allow empty files to be uploaded;
      if (file) return true;
      return false;
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      file: null,
    },

    validationSchema: UpdateDataFileSchema,
    onSubmit: (values, { setErrors, setSubmitting }) => {
      console.log('Data', values);
      onSubmitData(values, setSubmitting);
    },
  });

  const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  const onSelectFile = (event) => {
    const files = event.target.files
    if (isMultipleFileUpload) {
      setFieldValue('file', files);
      setFileName(`${files.length} files selected`);
    }
    else {
      setFieldValue('file', files[0]);
      setFileName(files[0]?.name);
    }
  };

  return (
    <Dialog {...props}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{description}</DialogContentText>

            <Stack spacing={{ xs: 2, md: 3 }} sx={{ mt: 1 }}>
              <Box
                sx={{
                  display: 'flex',
                  width: '440px',
                  height: 100,
                  border: '2px dashed gray',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderColor: 'primary.main',
                }}
              >
                <Stack direction="column" spacing={1} sx={{ alignItems: 'center' }}>
                  <Typography variant="body1">{fileName}</Typography>
                  <Button variant="contained" component="label" sx={{ width: '150px' }}>
                    Upload File
                    <input type="file" accept={accept} hidden onChange={onSelectFile} multiple={isMultipleFileUpload}/>
                  </Button>
                </Stack>
              </Box>
              <FormHelperText sx={{ color: 'red' }}>{touched.file && errors.file}</FormHelperText>
            </Stack>
            {!isEmpty(link) && (<Link href={link} variant="body1" underline="always">
              Sample File
            </Link>)}
          </DialogContent>
          <Typography variant="body2" color="red" sx={{ fontStyle: 'italic', textAlign: 'center', ml: 1, mr: 1 }}>
            {instruction}
          </Typography>
          <DialogActions>
            <Button onClick={onTapCancel}>Cancel</Button>
            <LoadingButton type="submit" variant="contained" loading={isSubmittingForm}>
              Upload
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}
