import { all } from 'redux-saga/effects';
import { watchLogin } from './Login.Saga';
import { watchClientSaga } from './Client.Saga';
import { watchUserSaga } from './User.Saga';
import { watchPortfolioSaga } from './Portfolio.Saga';
import { watchNewsSaga } from './News.Saga';
import { watchTemplateSaga } from './Template.Saga';
import { watchCompanySaga } from './Company.Saga';
import { watchCommonSaga } from './Common.Saga'
import { watchEsgSaga } from './Esg.Saga'

function* rootSaga() {
  yield all([
    watchLogin(),
    watchClientSaga(),
    watchUserSaga(),
    watchPortfolioSaga(),
    watchNewsSaga(),
    watchTemplateSaga(),
    watchCompanySaga(),
    watchCommonSaga(),
    watchEsgSaga()
  ]);
}

export default rootSaga;
