import * as types from '../constants/ActionTypes';

export const requestBulkUpload = (payload, onSuccess, onError) => ({
    type: types.BULK_UPLOAD_REQUEST,
    payload,
    onSuccess,
    onError
});

export const requestFilter = (payload, onSuccess, onError) => ({
    type: types.FILTER_REQUEST,
    payload,
    onSuccess,
    onError
});