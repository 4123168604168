import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';

// Get Company List
const getCompanyData = (state) => state?.company?.company_list;

export const getCompanyList = createSelector([getCompanyData], (list) => list);

// Get Company Dropdown data
const getCompanyDropDownData = (state) => {
  const arr_Companys = getCompanyData(state);
  return arr_Companys.map((item) => {
    return {
      name: item.name,
      id: item.companyUuid
    };
  });
};

export const getCompanyDropDownList = createSelector([getCompanyDropDownData], (list) => list);

// Get Company By Id
function getCompanyDetail(arr, companyId) {
  const company = arr.find((item) => item.companyUuid === companyId);
  return company;
}

export const getCompanyById = createSelector(
  [
    // Usual first input - extract value from `state`
    (state) => getCompanyData(state),
    // Take the second arg, `CompanyId`, and forward to the output selector
    (state, companyId) => companyId,
  ],
  // Output selector gets (`items, Companyid)` as args
  (items, companyId) => getCompanyDetail(items, companyId)
);


// Get Company Wise Client List
const getCompanyWiseClientData = (state) => state?.company?.company_wise_client_list;

export const getCompanyWiseClientList = createSelector([getCompanyWiseClientData], (list) => list);


// Get News List
const getNewsData = (state) => state?.company?.news_list;

export const getNewsist = createSelector([getNewsData], (list) => list);


