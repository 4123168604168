import React, { useState } from 'react'
import { Container } from '@mui/material';
import TemplateForm from 'src/sections/@dashboard/template/TemplateForm';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'src/routes/path';
import { useSelector } from 'react-redux';
import { getTemplateById } from '../../selectors/Template.Selector';
import { useParams } from 'react-router-dom'

const ViewTemplate = () => {
    const { templateId } = useParams()

    const { data } = useSelector((state) => ({
        data: getTemplateById(state, templateId)
    }))

    return (
        <Container>
            <HeaderBreadcrumbs
                heading="View Template"
                links={[
                    { name: 'Home', href: PATH_DASHBOARD.root },
                    { name: 'Template List', href: PATH_DASHBOARD.general.template_list },
                    { name: 'View Template' }
                ]}
            />
            <TemplateForm data={data}/>
        </Container>
    )
}

export default ViewTemplate