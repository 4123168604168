import { createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState: {
    portfolio_list: [],
  },
  reducers: {
    storePortfolioData: (state, action) => {
      const { data } = action.payload;
      state.portfolio_list = data;
    },
    deletePortfolioInfo: (state, action) => {
      const { portfolioId } = action.payload;
      state.portfolio_list = state.portfolio_list.filter((item) => item.portfolioId !== portfolioId);
    },
    changePortfolioApprovalStatus: (state, action) => {
      const { portfolioId, approvalstatus } = action.payload;
      state.portfolio_list = state.portfolio_list.map((item, index) => {
        if (item.portfolioId === portfolioId) {
          item['approvalstatus'] = approvalstatus;
        }
        return item;
      });
    },
    changePortfolioActivelStatus: (state, action) => {
      const { portfolioId, status } = action.payload;
      state.portfolio_list = state.portfolio_list.map((item, index) => {
        if (item.portfolioId === portfolioId) {
          item['status'] = status;
        }
        return item;
      });
    },
  },
});

export const { storePortfolioData, deletePortfolioInfo, changePortfolioApprovalStatus, changePortfolioActivelStatus } = portfolioSlice.actions;

export default portfolioSlice.reducer;
