import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { TextField, Alert, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { requestGeneratePassword } from '../../actions/Login.Action'
// hooks

import useIsMountedRef from '../../hooks/useIsMountedRef';

// ----------------------------------------------------------------------

GeneratePasswordForm.propTypes = {
    onSent: PropTypes.func,
    onGetEmail: PropTypes.func
};

export default function GeneratePasswordForm({ onSent, onGetEmail }) {
    const isMountedRef = useIsMountedRef();
    const dispatch = useDispatch()
    const { email, code } = useParams()
    const GeneratePasswordSchema = Yup.object().shape({
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        code: Yup.string().required("Code is required"),
        password: Yup.string().required('Password is required').min(6, 'Password should be of minimum 6 characters length'),
        confirmPassword: Yup.string()
            .required('Confirm password is required')
            .oneOf([Yup.ref('password')], "Password's not match"),
    });

    const formik = useFormik({
        initialValues: {
            email: email,
            code: code,
            password: '',
            confirmPassword: ''
        },
        validationSchema: GeneratePasswordSchema,
        onSubmit:  (values, { setErrors, setSubmitting }) => {
            dispatch(requestGeneratePassword(values.email, values.code, values.password, onForgotPasswordSuccess, onForgotPasswordError))
        }
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setSubmitting, setErrors } = formik;

    const onForgotPasswordSuccess = (data) => {
        if (isMountedRef.current) {
            onSent();
            onGetEmail(formik.values.email);
            setSubmitting(false);
        }
    }

    const onForgotPasswordError = (error) => {
        if (isMountedRef.current) {
            setErrors({ afterSubmit: error.message });
            setSubmitting(false);
        }
    }

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

                    <TextField
                        fullWidth
                        {...getFieldProps('email')}
                        disabled
                        type="email"
                        label="Email address"
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                    />
                    <TextField
                        fullWidth
                        {...getFieldProps('code')}
                        disabled
                        type="text"
                        label="Code"
                        error={Boolean(touched.code && errors.code)}
                        helperText={touched.code && errors.code}
                    />
                    <TextField
                        {...getFieldProps('password')}
                        fullWidth
                        autoComplete="on"
                        type="password"
                        label="New Password"
                        error={Boolean(touched.password && errors.password)}
                        helperText={(touched.password && errors.password) || 'Password must be minimum 6+'}
                    />

                    <TextField
                        {...getFieldProps('confirmPassword')}
                        fullWidth
                        autoComplete="on"
                        type="password"
                        label="Confirm New Password"
                        error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                        helperText={touched.confirmPassword && errors.confirmPassword}
                    />

                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                        Create Password
                    </LoadingButton>
                </Stack>
            </Form>
        </FormikProvider>
    );
}
