import { createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { REHYDRATE } from 'redux-persist/lib/constants';
import { setToken } from '../api';
const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    access_token: null,
    refreshToken: null
  },
  reducers: {
    storeLoginInfo: (state, action) => {
      const { user, access_token, refreshToken } = action.payload;
      state.user = user;
      state.access_token = access_token;
      state.refreshToken = refreshToken;
    },
    logout: (state, action) => {
      state.user = null;
      state.access_token = null;
      state.refreshToken = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action) => {
      const auth = action?.payload?.auth;
      if (!isEmpty(auth?.access_token)) {
        setToken(auth.access_token);
      }
    });
  },
});

export const { storeLoginInfo, logout } = authSlice.actions;

export default authSlice.reducer;
