import * as types from '../constants/ActionTypes';
import { put, takeLatest, call } from 'redux-saga/effects';
import ApiConstants from '../api/ApiConstants';
import { storeUserData, deleteUserInfo, changeUserApprovalStatus } from 'src/slices/User.Slice';
import API from '../api';

function* requestGetUserList(action) {
  const { payload, onSuccess, onError } = action;
  try {
    const response = yield call(API.post, ApiConstants.LIST_USER, payload);
    const { data } = response.data;
    const { allData } = data;
    yield put(storeUserData({ data: allData }));
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestAddUser(action) {
  const { payload, onSuccess, onError } = action;
  try {
    const response = yield call(API.post, ApiConstants.ADD_USER, payload);
    const data = response.data;
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestEditUser(action) {
  const { userId, payload, onSuccess, onError } = action;
  try {
    const response = yield call(API.put, ApiConstants.EDIT_USER(userId), payload);
    const data = response.data;
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestDeleteUser(action) {
  const { userId, onSuccess, onError } = action;
  try {
    const response = yield call(API.put, ApiConstants.DELETE_USER(userId), { status: 'delete' });
    yield put(deleteUserInfo({ userId }));
    onSuccess(userId);
  } catch (error) {
    onError(error);
  }
}

function* requestUpdateUserStatus(action) {
  const { userId, payload, onSuccess, onError } = action;
  try {
    const response = yield call(API.put, ApiConstants.STATUS_UPDATE_USER(userId), payload);
    const data = response.data;
    yield put(changeUserApprovalStatus({ userId: userId, approvalstatus: payload.approvalstatus }));
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestGetFilteredRecord(action) {
  const { payload, onSuccess, onError } = action;

  try {
    const response = yield call(API.post, ApiConstants.USER_FILTER, payload);
    const { data } = response.data;
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

export function* watchUserSaga() {
  yield takeLatest(types.LIST_USER_REQUEST_INITIATED, requestGetUserList),
    yield takeLatest(types.ADD_USER_REQUEST_INITIATED, requestAddUser),
    yield takeLatest(types.DELETE_USER_REQUEST, requestDeleteUser),
    yield takeLatest(types.EDIT_USER_REQUEST, requestEditUser),
    yield takeLatest(types.UPDATE_USER_STATUS_REQUEST, requestUpdateUserStatus),
    yield takeLatest(types.USER_FILTER_REQUEST, requestGetFilteredRecord);

}
