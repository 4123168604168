import React, { useState } from 'react'
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
// material
import { Box, Grid, Card, Stack, Switch, TextField, FormControlLabel, Typography, FormHelperText, Container } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddUser from 'src/sections/@dashboard/user/AddUser';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'src/routes/path';
import { useDispatch } from 'react-redux'
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { requestAddUser } from 'src/actions/User.Action';
import { useNavigate } from 'react-router-dom';


const CreateUser = () => {
    const isMountedRef = useIsMountedRef();
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setSubmitting] = useState(false)
    const navigate = useNavigate()

    const onSubmitData = (data) => {
        setSubmitting(true)
        dispatch(requestAddUser(data, onSuccessAddUser, onErrorAddUser))
    }

    const onSuccessAddUser = (data) => {
        setSubmitting(false)
        enqueueSnackbar('User created successfully', {
            variant: 'success'
        });
        navigate(PATH_DASHBOARD.general.user_list, { replace: true })
    }

    const onErrorAddUser = (error) => {
        setSubmitting(false)
        const message = error?.response?.data?.message || error?.message || "Please try again."
        enqueueSnackbar(message, {
            variant: 'error'
        });
    }

    return (
        <Container>
            <HeaderBreadcrumbs
                heading="Add User"
                links={[
                    { name: 'Home', href: PATH_DASHBOARD.root },
                    { name: 'User List', href: PATH_DASHBOARD.general.user_list },
                    { name: 'Add User' }
                ]}
            />
            <AddUser onSubmitData={onSubmitData} isLoading={isSubmitting}/>
        </Container>
    )
}

export default CreateUser