import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { useDispatch } from 'react-redux';
import { loginRequest } from '../../../actions/Login.Action';
import { Link as RouterLink } from 'react-router-dom';
import { PATH_AUTH, PATH_DASHBOARD } from '../../../routes/path';
import useAuth from '../../../hooks/useAuth';
import { APP_LOGIN_CREDENTIALS } from '../../../constants/AppConstants';
import { useModal } from 'mui-modal-provider';
import isEmpty from 'lodash/isEmpty';
import AlertDialog from '../../../components/AlertDialog';
import { storeLoginInfo } from '../../../slices/Login.Slice';
import { setToken } from '../../../api';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isRemeberMe, setIsRememberMe] = useState(false);

  const [isShowAlert, setIsShowAlert] = useState(false);

  const { storeUserInfo, getUserInfoFromVault, clearuserInfo } = useAuth();

  const { showModal, hideModal } = useModal();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const handleClick = () => {
    navigate('/dashboard', { replace: true });
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: (values, { setErrors, setSubmitting, resetForm }) => {
      setSubmitting(true);
      dispatch(loginRequest(values.email, values.password, onLoginSuccess, onLoginError));
    },
  });

  const {
    errors,
    touched,
    values,
    setSubmitting,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    resetForm,
    setErrors,
    setFieldValue,
  } = formik;

  useEffect(() => {
    const info = getUserInfoFromVault(APP_LOGIN_CREDENTIALS);
    if (!isEmpty(info)) {
      setIsRememberMe(true);
      const { email, password } = info;
      setFieldValue('email', email);
      setFieldValue('password', password);
    }
  }, [isMountedRef.current]);

  const onLoginSuccess = (data) => {
    if (isMountedRef.current) {
      setSubmitting(false);
    }
    resetForm();
    const { user, accessToken, refreshToken } = data;
    if (user.role === 'client') {
      const modal = showModal(AlertDialog, {
        title: 'Permission Denied',
        description: 'You are not allowed to login as a client. Please contact your system administrator',
        onConfirm: () => {
          modal.hide();
        },
      });
    }
    else if (user.firstlogin === 1) {
      navigate(PATH_AUTH.create_user_password, { state: { user, accessToken }})
    } 
    else {
      enqueueSnackbar('Login success', {
        variant: 'success',
      });
      if (isRemeberMe) {
        storeUserInfo(APP_LOGIN_CREDENTIALS, { email: values.email, password: values.password });
      } else {
        clearuserInfo(APP_LOGIN_CREDENTIALS);
      }
      setToken(accessToken);
      dispatch(storeLoginInfo({ user: user, access_token: accessToken, refreshToken: refreshToken }));
    }
  };

  const onLoginError = (error) => {
    const message = error?.response?.data?.message || error?.message || 'Please try again.';
    // enqueueSnackbar('Invalid Email or password', { variant: 'error' });
    if (isMountedRef.current) {
      setSubmitting(false);
      setErrors({ afterSubmit: 'Invalid Email or password' });
      enqueueSnackbar(message, {
        variant: 'error',
      });
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox onChange={(e) => setIsRememberMe(e.target.checked)} checked={isRemeberMe} />}
            label="Remember me"
          />
          <Link variant="subtitle2" underline="hover" component={RouterLink} to={PATH_AUTH.forgot_password}>
            Forgot Password?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="button"
          variant="contained"
          loading={isSubmitting}
          onClick={handleSubmit}
        >
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
