import * as types from '../constants/ActionTypes';
import { put, takeLatest, call } from 'redux-saga/effects';
import ApiConstants from '../api/ApiConstants';
import { storeClientData, deleteClientInfo, changeClientApprovalStatus, storeDefaultClientData } from 'src/slices/Client.Slice';
import API from '../api';

function* requestGetClientList(action) {
  const { onSuccess, onError } = action;
  try {
    const response = yield call(API.get, ApiConstants.LIST_CLIENT);
    const { data } = response.data;
    yield put(storeClientData({ data: data }));
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestAddClient(action) {
  const { payload, onSuccess, onError } = action;
  try {
    const headers = { 'Content-Type': 'multipart/form-data' };
    const response = yield call(API.post, ApiConstants.CREATE_CLIENT, payload, headers);
    const data = response.data;
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestEditClient(action) {
  const { clientId, payload, onSuccess, onError } = action;
  try {
    const headers = { 'Content-Type': 'multipart/form-data' };
    const response = yield call(API.put, ApiConstants.EDIT_CLIENT(clientId), payload, headers);
    const data = response.data;
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestDeleteClient(action) {
  const { clientId, onSuccess, onError } = action;
  try {
    const payload = { "status": 'delete' }
    const response = yield call(API.put, ApiConstants.STATUS_UPDATE_CLIENT(clientId), payload);
    yield put(deleteClientInfo({ clientId }));
    onSuccess(clientId);
  } catch (error) {
    onError(error);
  }
}

function* requestUpdateClientStatus(action) {
  const { clientId, payload, onSuccess, onError } = action;
  try {
    const response = yield call(API.put, ApiConstants.STATUS_UPDATE_CLIENT(clientId), payload);
    const data = response.data;
    yield put(changeClientApprovalStatus({ clientId: clientId, approvalstatus: payload.approvalstatus }));
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

function* requestGetDefaultClientList(action) {
  const { onSuccess, onError } = action;
  try {
    const response = yield call(API.get, ApiConstants.LIST_DEFAULT_CLIENT);
    const { data } = response.data;
    yield put(storeDefaultClientData({ data: data }));
    onSuccess(response);
  } catch (error) {
    onError(error);
  }
}

export function* watchClientSaga() {
  yield takeLatest(types.LIST_CLIENT_REQUEST_INITIATED, requestGetClientList),
    yield takeLatest(types.ADD_CLIENT_REQUEST_INITIATED, requestAddClient),
    yield takeLatest(types.DELETE_CLIENT_REQUEST, requestDeleteClient),
    yield takeLatest(types.EDIT_CLIENT_REQUEST, requestEditClient),
    yield takeLatest(types.UPDATE_CLIENT_STATUS_REQUEST, requestUpdateClientStatus);
    yield takeLatest(types.LIST_DEFAULT_CLIENT_REQUEST, requestGetDefaultClientList);
}
