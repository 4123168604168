import * as Yup from 'yup';
import { useCallback, useState, useEffect } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { Theme, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Card,
  Stack,
  OutlinedInput,
  TextField,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Switch,
  Typography,
  FormLabel,
  Autocomplete,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import isEmpty from 'lodash/isEmpty';
import useGetClientList from '../../../hooks/useGetClientList';
import useGetCompanyInfo from '../../../hooks/useGetCompanyInfo';
import {
  ALPHA_NUMERIC_REGEX,
  NAV_FREQUENCY,
  PORTFOLIO_STRATEGY,
  PORTFOLIO_TYPE,
  REGULATED_AUTHORITY,
} from '../../../constants/AppConstants';
import { requestGetActiveCompanyList } from '../../../actions/Company.Action';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import countries from 'src/utils/Countries';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

const REGULATED_LIST = ['Yes', 'No'];

const PortfolioForm = ({ data, onSubmitData, isLoading }) => {
  const theme = useTheme();
  const UpdateUserSchema = Yup.object().shape({
    name: Yup.string().required('Please enter valid Name'),
    code: Yup.string().required('Please enter valid Code'),
    description: Yup.string().required('Please enter valid Description'),
    portfoliotype: Yup.string().required('Please select valid type'),
    portfoliostratgy: Yup.string().required('Please select valid strategy'),
    domiciliation: Yup.string().required('Please select valid domicile'),
    regulated: Yup.string().required('Please select valid info'),
    regulatoryauthority: Yup.string().when('regulated', {
      is: 'Yes',
      then: Yup.string().required('Please select valid authority'),
    }),
    navfrequency: Yup.string().required('Please select valid frequency'),
    company: Yup.array().of(Yup.string()).min(1, 'Please select valid Company').required('Please select valid Company'),
    client: Yup.string().required('Please select valid Client'),
  });

  const { dropDownData } = useGetClientList();

  const { dropDownCompanyData } = useGetCompanyInfo();

  const isPublicClient = isEmpty(data) ? false : data.clientName === 'ESGWise Public Default';

  const [iSAssociateWithDefaultClient, setIsAssociateWithDefaultClient] = useState(isPublicClient);

  const [clientList, setClientList] = useState(dropDownData);

  const [activeCompanyList, setActiveCompanyList] = useState([]);

  const [isLoadingActiveCompanyList, setIsLoadingActiveCompanyList] = useState(false);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   const mode = iSAssociateWithDefaultClient ? 'publicclient' : 'client';
  //   console.log(dropDownData);
  //   setClientList(dropDownData.filter((item) => item.mode === mode));
  // }, [iSAssociateWithDefaultClient]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.name || '',
      code: data?.code || '',
      portfoliotype: data?.portfoliotype || 'Investment Fund',
      portfoliostratgy: data?.portfoliostratgy || '',
      domiciliation: data?.domiciliation || '',
      regulated: data?.regulated || '',
      regulatoryauthority: data?.regulatoryauthority || '',
      description: data?.description || '',
      navfrequency: data?.navfrequency || '',
      company: data?.company || [],
      client: data?.client || '',
      status: data?.status || 'active',
    },

    validationSchema: UpdateUserSchema,
    onSubmit: (values, { setErrors, setSubmitting }) => {
      console.log('Data', values);
      onSubmitData(values);
    },
  });

  const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  useEffect(() => {
    if (!isEmpty(data)) {
      requestGetActiveCompanyListInfo(data.client);
      setFieldValue('client', data.client);
    }
  }, [data]);

  const isDisabled = isEmpty(data) ? false : true;

  const requestGetActiveCompanyListInfo = (clientId) => {
    const payload = {
      client: clientId,
    };
    setIsLoadingActiveCompanyList(true);
    dispatch(requestGetActiveCompanyList(payload, onGetActiveCompanyListSuccess, onGetActiveCompanyListError));
  };

  const onGetActiveCompanyListSuccess = (data) => {
    setIsLoadingActiveCompanyList(false);
    setActiveCompanyList(data);
  };

  const onGetActiveCompanyListError = (error) => {
    setIsLoadingActiveCompanyList(false);
  };

  const onSelecDropDown = useCallback(
    (e) => {
      const value = e.target.value;
      setFieldValue('company', value);
    },
    [setFieldValue]
  );

  const handleChange = (event) => {
    const value = event.target.checked;
    const portfolioStatus = value === true ? 'active' : 'inactive';
    setFieldValue('status', portfolioStatus);
  };

  const onSelectCompanyDropDown = useCallback(
    (e, val) => {
      const value = e.target.value;
      const arr = val.map((item) => item.companyUuid);
      setFieldValue('company', arr);
    },
    [setFieldValue]
  );

  const onSelectClientDropDown = useCallback(
    (e) => {
      const value = e.target.value;
      setFieldValue('client', value);
      const client = clientList.find((item) => item.id === value);
      setFieldValue('clientName', client.name);
      requestGetActiveCompanyListInfo(value);
    },
    [setFieldValue]
  );

  const onSelectPortFolioTypeDropDown = useCallback(
    (e) => {
      const value = e.target.value;
      setFieldValue('portfoliotype', value);
    },
    [setFieldValue]
  );

  const onSelectPortfolioStrategyDropDown = useCallback(
    (e) => {
      const value = e.target.value;
      setFieldValue('portfoliostratgy', value);
    },
    [setFieldValue]
  );

  const onSelectDomicilationDropDown = useCallback(
    (e, value) => {
      setFieldValue('domiciliation', value.label);
    },
    [setFieldValue]
  );

  const onSelectRegulatedDropDown = useCallback(
    (e) => {
      const value = e.target.value;
      setFieldValue('regulated', value);
    },
    [setFieldValue]
  );

  const onSelectRegulatedAuthorityDropDown = useCallback(
    (e) => {
      const value = e.target.value;
      setFieldValue('regulatoryauthority', value);
    },
    [setFieldValue]
  );

  const onSelectNavFrequencyDropDown = useCallback(
    (e) => {
      const value = e.target.value;
      setFieldValue('navfrequency', value);
    },
    [setFieldValue]
  );

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={{ xs: 2, md: 3 }}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    label="Name"
                    disabled={isDisabled}
                    {...getFieldProps('name')}
                    inputProps={{
                      maxLength: 50,
                    }}
                    onKeyDown={(event) => {
                      if (!ALPHA_NUMERIC_REGEX.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                  <TextField
                    fullWidth
                    label="Code"
                    disabled={isDisabled}
                    {...getFieldProps('code')}
                    inputProps={{
                      maxLength: 30,
                    }}
                    onKeyDown={(event) => {
                      if (!ALPHA_NUMERIC_REGEX.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    error={Boolean(touched.code && errors.code)}
                    helperText={touched.code && errors.code}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ mt: 3 }}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-portfolio-type-label">Portfolio Type</InputLabel>
                    <Select
                      labelId="demo-portfolio-type-label"
                      id="demo-portfolio-type-label"
                      fullWidth
                      value={values.portfoliotype}
                      onChange={onSelectPortFolioTypeDropDown}
                      input={<OutlinedInput id="demo-portfolio-type" label="Portfolio Type" />}
                      error={Boolean(touched.portfoliotype && errors.portfoliotype)}
                      MenuProps={MenuProps}
                    >
                      {PORTFOLIO_TYPE.map((item) => (
                        <MenuItem key={item.name} value={item.name} style={getStyles(item.name, item.name, theme)}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText sx={{ color: 'red' }}>
                      {touched.portfoliotype && errors.portfoliotype}
                    </FormHelperText>
                  </FormControl>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-portfolio-strategy-label">Portfolio Strategy</InputLabel>
                    <Select
                      labelId="demo-portfolio-strategy-label"
                      id="demo-portfolio-strategy-label"
                      fullWidth
                      value={values.portfoliostratgy}
                      onChange={onSelectPortfolioStrategyDropDown}
                      input={<OutlinedInput id="demo-portfolio-strategy" label="Portfolio Strategy" />}
                      error={Boolean(touched.portfoliostratgy && errors.portfoliostratgy)}
                      MenuProps={MenuProps}
                    >
                      {PORTFOLIO_STRATEGY.map((item) => (
                        <MenuItem key={item.name} value={item.name} style={getStyles(item.name, item.name, theme)}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText sx={{ color: 'red' }}>
                      {touched.portfoliostratgy && errors.portfoliostratgy}
                    </FormHelperText>
                  </FormControl>
                </Stack>
                <FormControl sx={{ width: '100%' }}>
                  <TextField
                    fullWidth
                    label="Description"
                    disabled={isDisabled}
                    placeholder=""
                    {...getFieldProps('description')}
                    multiline={true}
                    maxRows={15}
                    inputProps={{
                      maxLength: 100,
                    }}
                    onKeyDown={(event) => {
                      if (!ALPHA_NUMERIC_REGEX.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    variant="filled"
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </FormControl>
                <Stack direction={{ xs: 'column', md: 'column' }}>
                  <Autocomplete
                    labelId="demo-single-client-label"
                    id="demo-single-client"
                    fullWidth
                    multiple={false}
                    value={countries.find((item) => values?.domiciliation === item.label)}
                    filterSelectedOptions
                    options={countries}
                    getOptionLabel={(option) => option.label}
                    onChange={onSelectDomicilationDropDown}
                    renderInput={(params) => (
                      <TextField {...params} label="Domiciliation" placeholder="Domiciliation" />
                    )}
                  />
                  <FormHelperText sx={{ color: 'red', marginLeft: '15px' }}>{touched.domiciliation && errors.domiciliation}</FormHelperText>
                </Stack>
                <Stack direction={{ xs: 'column', md: 'row' }}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-regulated-label">Regulated</InputLabel>
                    <Select
                      labelId="demo-regulated-label"
                      id="demo-regulated-label"
                      fullWidth
                      value={values.regulated}
                      onChange={onSelectRegulatedDropDown}
                      input={<OutlinedInput id="demo-regulated" label="Regulated" />}
                      error={Boolean(touched.regulated && errors.regulated)}
                      MenuProps={MenuProps}
                    >
                      {REGULATED_LIST.map((item) => (
                        <MenuItem key={item} value={item} style={getStyles(item, item, theme)}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText sx={{ color: 'red' }}>{touched.regulated && errors.regulated}</FormHelperText>
                  </FormControl>
                </Stack>
                {values.regulated === 'Yes' && (
                  <Stack direction={{ xs: 'column', md: 'row' }}>
                    <FormControl sx={{ width: '100%' }}>
                      <InputLabel id="demo-regulated-authority-label">Regulated Authority</InputLabel>
                      <Select
                        labelId="demo-regulated-authority-label"
                        id="demo-regulated-authority-label"
                        fullWidth
                        value={values.regulatoryauthority}
                        onChange={onSelectRegulatedAuthorityDropDown}
                        input={<OutlinedInput id="demo-regulated" label="Regulated Authority" />}
                        error={Boolean(touched.regulatoryauthority && errors.regulatoryauthority)}
                        MenuProps={MenuProps}
                      >
                        {REGULATED_AUTHORITY.map((item) => (
                          <MenuItem key={item.name} value={item.name} style={getStyles(item.name, item.name, theme)}>
                            {item.value}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText sx={{ color: 'red' }}>
                        {touched.regulatoryauthority && errors.regulatoryauthority}
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                )}
                <Stack direction={{ xs: 'column', md: 'row' }}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-nav-frequency-label">NAV Frequency</InputLabel>
                    <Select
                      labelId="demo-nav-frequency-label"
                      id="demo-nav-frequency-label"
                      fullWidth
                      value={values.navfrequency}
                      onChange={onSelectNavFrequencyDropDown}
                      input={<OutlinedInput id="demo-nav-frequency" label="NAV Frequency" />}
                      error={Boolean(touched.navfrequency && errors.navfrequency)}
                      MenuProps={MenuProps}
                    >
                      {NAV_FREQUENCY.map((item) => (
                        <MenuItem key={item.name} value={item.name} style={getStyles(item.name, item.name, theme)}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText sx={{ color: 'red' }}>{touched.navfrequency && errors.navfrequency}</FormHelperText>
                  </FormControl>
                </Stack>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ mt: 3 }}>
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-single-client-label">Client</InputLabel>
                    <Select
                      labelId="demo-single-client-label"
                      id="demo-single-client"
                      fullWidth
                      disabled={isDisabled}
                      value={values.client}
                      onChange={onSelectClientDropDown}
                      input={<OutlinedInput id="demo-single-client" label="Client" />}
                      error={Boolean(touched.client && errors.client)}
                      MenuProps={MenuProps}
                    >
                      {dropDownData.map((item) => (
                        <MenuItem key={item.id} value={item.id} style={getStyles(item.name, item.name, theme)}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText sx={{ color: 'red' }}>{touched.client && errors.client}</FormHelperText>
                  </FormControl>
                </Stack>
                {isLoadingActiveCompanyList ? (
                  <CircularProgress />
                ) : (
                  // <FormControl sx={{ width: '100%' }}>
                  //   <InputLabel id="demo-multiple-chip-label">Company</InputLabel>
                  //   <Select
                  //     labelId="demo-multiple-chip-label"
                  //     id="demo-multiple-chip"
                  //     fullWidth
                  //     multiple
                  //     value={values.company}
                  //     onChange={onSelectCompanyDropDown}
                  //     input={<OutlinedInput id="demo-multiple-chip" label="Company" />}
                  //     error={Boolean(touched.company && errors.company)}
                  //     MenuProps={MenuProps}
                  //   >
                  //     {activeCompanyList.map((item) => (
                  //       <MenuItem
                  //         key={item.name}
                  //         value={item.companyUuid}
                  //         style={getStyles(item.name, item.name, theme)}
                  //       >
                  //         {item.name}
                  //       </MenuItem>
                  //     ))}
                  //   </Select>
                  //   <FormHelperText sx={{ color: 'red' }}>{touched.company && errors.company}</FormHelperText>
                  // </FormControl>
                  <FormControl sx={{ width: '100%' }}>
                    <Autocomplete
                      labelId="demo-single-client-label"
                      id="demo-single-client"
                      fullWidth
                      multiple={true}
                      value={activeCompanyList.filter((item) => values?.company.includes(item.companyUuid))}
                      filterSelectedOptions
                      options={activeCompanyList}
                      getOptionLabel={(option) => option.name}
                      onChange={onSelectCompanyDropDown}
                      renderInput={(params) => <TextField {...params} label="Company" placeholder="Company" />}
                    />
                  </FormControl>
                )}
                {/* <Stack direction="row" spacing={2} alignItems="center">
                  <FormLabel component="legend">Status</FormLabel>
                  <Typography>In-Active</Typography>
                  <Switch
                    {...getFieldProps('status')}
                    color="primary"
                    checked={values.status === 'active'}
                    onChange={handleChange}
                  />
                  <Typography>Active</Typography>
                </Stack> */}
              </Stack>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton type="submit" variant="contained" loading={isLoading}>
                  Save Changes
                </LoadingButton>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

PortfolioForm.propTypes = {
  data: PropTypes.object,
  onSubmitData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default PortfolioForm;
